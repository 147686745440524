import { useEffect, useMemo, useRef } from 'react'
import { Platform, Dimensions as RNDimensions } from 'react-native'
import androidTemplateString from './androidTemplateString.utils'
import { ICMSText } from '..'

export const useIsMounted = () => {
  const isMountedRef = useRef(true)

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    [],
  )

  return isMountedRef.current
}

export const isWeb = Platform.OS === 'web'
export const isAndroid = Platform.OS === 'android'
export const isiOS = Platform.OS === 'ios'
export const Dimensions = RNDimensions.get('window')

const numberCeilingFunctionAux = function (value: any, factor = 10) {
  const parsedValue = parseFloat(value)
  const result =
    parsedValue > 0
      ? Math.ceil(((parsedValue + Number.EPSILON) * factor) / factor)
      : 0
  return result
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/\.0+$/, '')
}

const formatNumberAux = function (
  value: any,
  decimalPlacesParam: number,
  factor = 10,
) {
  const decimalPlacesByFactor = factor === 10 ? 1 : 2
  const decimalPlaces =
    decimalPlacesParam !== null ? decimalPlacesParam : decimalPlacesByFactor
  const result = (
    ((parseFloat(value) + Number.EPSILON) * factor) /
    factor
  ).toFixed(decimalPlaces)
  return result
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/\.0+$/, '')
}

// Needed to be used outside this module
export const formatNumber = formatNumberAux

const formatNumberFunction = `function formatNumber(...args) {
  const aux = ${formatNumberAux.toString()}
  return aux.call(null, ...args)
}`

const numberCeilingFunction = `function numberCeiling(...args) {
  const aux = ${numberCeilingFunctionAux.toString()}
  return aux.call(null, ...args)
}`

export const fillTemplateString = androidTemplateString

export const fillTemplateCMSText = (input: ICMSText, contextData: any) => {
  const parsedValues = input.values?.map(({ value, ...props }) => ({
    value: fillTemplateString(value, contextData),
    ...props
  }))
  return { ...input, values: parsedValues }
}

export const useTemplatedString = (input: ICMSText, data: any) => {
  const memoizedValue = useMemo(() => fillTemplateCMSText(input, data), [input])
  return memoizedValue
}

export const useTemplatedStringArray = (input: ICMSText[], data: any) => {
  const memoizedValue = useMemo(() => {
    const parsedValues = input.map(singleInput =>
      fillTemplateCMSText(singleInput, data),
    )
    return parsedValues
  }, [input])

  return memoizedValue
}

export const NULL_VALUE_NUMBERS = '-'

export const addInchesMark = (value: number | string, suffix: string = '”') =>
  value === NULL_VALUE_NUMBERS ? value : `${value}${suffix}`

export const getFixedValue = (
  value: number,
  fixedPositions: number,
  defaultValue: string = NULL_VALUE_NUMBERS,
) => (value ? value.toFixed(fixedPositions) : defaultValue)

export const transformCMSTextToString = (input: ICMSText) => {
  const { values = [] } = input
  return values.map(({ value }) => value).join(' ')
}
