import React from 'react'
import { Text, View } from 'native-base'
import { Defs, Path, ClipPath, G, Rect } from 'react-native-svg'
import {  addInchesMark,  NULL_VALUE_NUMBERS,  getFixedValue} from '../../../utils'
import RoofImage from './BaseDiagramImage'
import { ICalculationsIWS } from '../CalculatorNote/CalculatorNote.types'

export interface IIWSDiagramProps extends ICalculationsIWS {}

const DEFAULT_INPUTS = {} as ICalculationsIWS['inputs']
const DEFAULT_INTERMEDIATE_VALUES = {} as ICalculationsIWS['intermediateValues']

const strokeDasharray = ' 6'
const strokeWidth = '5'

const Meassure = () => (
  <>
    <Defs>
      <ClipPath id="__lottie_element_2">
        <Rect width="610" height="650" x="0" y="0" />
      </ClipPath>
    </Defs>
    <G clipPath="url(#__lottie_element_2)">
      <G
        transform="matrix(1,0,0,1,271.26800537109375,585.9700317382812)"
        opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path fill="rgb(255,255,255)" fill-opacity="1" d="M0 0" />
          <Path
            strokeLinecap="butt"
            fillOpacity="0"
            stroke="rgb(255,184,41)"
            d="M0,6.442999839782715 C0,6.442999839782715 25.534000396728516,0 25.534000396728516,0"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
      <G
        transform="matrix(1,0,0,1,324.8940124511719,131.01499938964844)"
        opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path fill="rgb(255,255,255)" fillOpacity="1" d="M0 0" />
          <Path
            strokeLinecap="butt"
            fillOpacity="0"
            stroke="rgb(255,184,41)"
            d=" M209.39500427246094,240.77000427246094 C209.39500427246094,240.77000427246094 209.39500427246094,0 209.39500427246094,0 M0,267.9159851074219 C0,267.9159851074219 209.39500427246094,240.77000427246094 209.39500427246094,240.77000427246094"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
      <G
        transform="matrix(1,0,0,1,188.7689971923828,409.04400634765625)"
        opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path fill="rgb(255,255,255)" fill-opacity="1" d="M0 0" />
          <Path
            strokeLinecap="butt"
            fillOpacity="0"
            stroke="rgb(255,184,41)"
            d=" M0,11.979000091552734 C0,11.979000091552734 77.0979995727539,0 77.0979995727539,0"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
      <G
        transform="matrix(1,0,0,1,363.7660217285156,-1.2410049438476562)"
        opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path fill="rgb(255,255,255)" fill-opacity="1" d="M0 0" />
          <Path
            strokeLinecap="butt"
            fillOpacity="0"
            stroke="rgb(255,184,41)"
            d=" M0,143.59800720214844 C0,143.59800720214844 167.5240020751953,0 167.5240020751953,0"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
      <G transform="matrix(1,0,0,1,161.125,206.59298706054688)" opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path fill="rgb(255,255,255)" fill-opacity="1" d="M0 0" />
          <Path
            strokeLinecap="butt"
            fillOpacity="2"
            stroke="rgb(255,184,41)"
            d=" M127.73400115966797,0 C127.73400115966797,0 0,114.41500091552734 0,114.41500091552734"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
    </G>
  </>
)

const IWSDiagram = ({
  inputs = DEFAULT_INPUTS,
  intermediateValues = DEFAULT_INTERMEDIATE_VALUES
}: IIWSDiagramProps) => (
  <View position="relative" overflow="hidden" w="100%" h="auto" padding="1.5">
    <RoofImage MeassureComponent={Meassure} />
    <View position="absolute" top="25%" left="50%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500">
        {addInchesMark(getFixedValue(intermediateValues.D, 1))}
      </Text>
    </View>
    <View position="absolute" top="55%" left="35%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500"
        zIndex={100}>
        {inputs.roofPitch ? `${inputs.roofPitch}/12` : NULL_VALUE_NUMBERS}
      </Text>
    </View>
    <View position="absolute" top="68%" left="35%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500">
        {addInchesMark(getFixedValue(inputs.soffitDepth, 1))}
      </Text>
    </View>
    <View position="absolute" top="63%" left="65%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500">
        {addInchesMark(getFixedValue(inputs.insideInteriorWall, 1))}
      </Text>
    </View>
    <View position="absolute" top="35%" left="90%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500">
        {addInchesMark(getFixedValue(intermediateValues.E, 1))}
      </Text>
    </View>
    <View position="absolute" top="93%" left="46%">
      <Text
        fontSize={{ base: 'xs', sm: 'lg' }}
        fontWeight="bold"
        color="primary.500">
        {addInchesMark(getFixedValue(intermediateValues.B, 1))}
      </Text>
    </View>
  </View>
)

export default IWSDiagram
