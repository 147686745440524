import React, { useEffect, useState } from 'react'
import Icon from '../Icon'
import { IconName } from '../Icon/types.icon'

const LOW_SLOPE = 'low_slope'
const STEEP_SLOPE = 'steep_slope'
const RESIDENTIAL = 'residential'

export type Project = {
  roofType?: string;
  propertyType?: string;
  isSample?: boolean;
};

export interface IconProps {
  roofType?: string;
  propertyType?: string;
  isSample?: boolean;
}

const PropertyTypeIcon = ({ roofType, propertyType, isSample, ...rest }: IconProps) => {
  const [name, setName] = useState('' as IconName)

  useEffect(() => {
    let _name = ''

    if (propertyType === RESIDENTIAL) {
      _name = 'residentialProperty'
    } else {
      _name = 'commercialProperty'
    }

    if (roofType === LOW_SLOPE) {
      _name = propertyType === RESIDENTIAL ? 'propertyirclow' : 'propertyibclow'
    } else if (roofType === STEEP_SLOPE) {
      _name = propertyType === RESIDENTIAL ? 'propertyircsteep' : 'propertyibcsteep'
    }

    if (isSample) _name = 'propertyircsteep'

    setName(_name as IconName)

  }, [roofType, propertyType, isSample])

  if (!propertyType) return null

  return (
    <Icon name={name} size={6} {...rest} />
  )
}

export default PropertyTypeIcon
