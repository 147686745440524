import React from 'react'
import { VStack, Divider, HStack, ITextProps } from 'native-base'
import { ITooltipProps } from '../../__Helpers/Tooltip'
import CMSText, { ISingleCMSValueText } from '../CMSText'

export interface ITwoColumnLineItemProps {
  title: ISingleCMSValueText[]
  value: ISingleCMSValueText[]
  showTooltip?: boolean
  tooltipProps?: ITooltipProps
  isNative?: boolean
}

const baseValueProps: ITextProps = {
  textAlign: 'right',
  fontSize: 'lg',
  fontWeight: 'semibold',
  _web: {
    flex: '1'
  }
}

const BaseModal: React.FC<ITwoColumnLineItemProps> = ({
  title,
  value,
  showTooltip = false,
  tooltipProps = {},
  isNative = false
}) => {
  const expandedValue = value.map(item => ({
    ...baseValueProps,
    ...item,
    color: 'primary.400'
  }))
  return (
    <VStack w="100%">
      <HStack
        space="2"
        justifyContent="space-between"
        alignItems="center"
        mb="2">
        <CMSText
          {...baseValueProps}
          _web={{
            verticalAlign: 'center',
            lineHeight: 1.5
          }}
          values={title}
          showTooltip={showTooltip}
          tooltipProps={tooltipProps}
          isNative={isNative}
        />
        <CMSText values={expandedValue} />
      </HStack>
      <Divider />
    </VStack>
  )
}

export default BaseModal
