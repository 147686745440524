import { ColorMode, View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import CanopyWeather, { PartnerLogoVariantProps } from './variants/CanopyWeather'

type PartnerLogoVariant = 'canopy'

type PartnerLogoVariantComponent = (props: PartnerLogoVariantProps) => JSX.Element
type Sources = Record<PartnerLogoVariant, PartnerLogoVariantComponent>
const PartnerLogoSources: Sources = {
  canopy: CanopyWeather
}

export interface PartnerLogoProps extends IViewProps {
  partner: PartnerLogoVariant
  variant?: PartnerLogoVariantProps['variant']
  colorMode?: ColorMode
  colorVariant?: number
}

const PartnerLogo = (props: PartnerLogoProps): JSX.Element => {
  const { partner, colorVariant, variant, ...rest } = props
  const colorMode = props.colorMode ?? 'dark'

  const LogoSVG = PartnerLogoSources[partner]

  return (
    <View {...rest}>
      <LogoSVG colorMode={colorMode} colorVariant={colorVariant} variant={variant} />
    </View>
  )
}

export default PartnerLogo
