import React, { useRef } from 'react'
import {
  Button,
  HStack,
  Text,
  VStack,
  View,
  useClipboard,
  useBreakpointValue,
  Box
} from 'native-base'
import {
  ICalculationsIWS,
  ICalculationsWaste,
  ICalculationVentilation,
  ICalculatorNoteSection,
  IExtendedCMSTextProps,
  ISectionContent
} from './CalculatorNote.types'
import {
  fillTemplateString,
  isWeb,
  useTemplatedStringArray,
  transformCMSTextToString
} from '../../../utils'
import CMSText from '../CMSText'
import Icon from '../../Icon'
import useOCCToast from '../../../hooks/useToast'

type Calculators =
  | ICalculationsIWS
  | ICalculationVentilation
  | ICalculationsWaste

export interface ICalculatorNoteProps {
  note: ICalculatorNoteSection
  Collapsable: React.FC<any>
  calculations: Calculators
  nativeOnCopy?: Function
}

interface IUseClipboard {
  onCopy: (copiedValue: string) => Promise<void>
}

const DEFAULT_CMS_TEXT_PROPS = [] as IExtendedCMSTextProps[]

const DEFAULT_NOTE = {
  content: {
    description: DEFAULT_CMS_TEXT_PROPS,
    valueLines: DEFAULT_CMS_TEXT_PROPS
  } as ISectionContent
} as ICalculatorNoteSection

const DEFAULT_CALCULATIONS = {} as Calculators

const DEFAULT_COLLAPSABLE = ({ children }: any) => children

const getKey = (prefix: string, index: number) => `${prefix}-${index}`

const CalculatorNote = ({
  note = DEFAULT_NOTE,
  calculations = DEFAULT_CALCULATIONS,
  Collapsable = DEFAULT_COLLAPSABLE,
  nativeOnCopy
}: ICalculatorNoteProps) => {
  const isMobileWeb = useBreakpointValue({ base: true, md: false })
  const { show } = useOCCToast()
  const dataContainer = useRef({} as any)
  const { onCopy: nativeBaseOnCopy } = useClipboard()

  const nativeCopyHandler = nativeOnCopy ?? nativeBaseOnCopy

  const { title, value, percentageValue, content } = note
  const { description, title: contentTitle, valueLines } = content

  const parsedTitleValue = fillTemplateString(value, calculations)
  const parsedTitlePercentage =
    percentageValue && fillTemplateString(percentageValue, calculations)

  const parsedValueLines = useTemplatedStringArray(valueLines, calculations)
  let parsedDescription = useTemplatedStringArray(description, calculations)
  parsedDescription = parsedDescription.filter(d => d.values?.filter(v => v.value)?.length)

  const CopyStyledNote = async (sourceString: String) => {
    try {
      let range: Range
      let selection: Selection | null
      const doc = document as any
      const isFirefox = navigator.userAgent.includes("Firefox")

      // CREATE DYNAMIC CONTAINER
      const container = doc.createElement('p')
      container.innerText = sourceString.replace('<strong>', '').replace('</strong>', '').replace('<br/>', '\n')
      container.innerHTML = sourceString

      container.style.fontFamily = "Helvetica"
      container.style.setProperty('fontFamily', 'Helvetica')
      container.style.fontSize = "12px"
      container.style.setProperty('fontSize', '12px')

      if (!isFirefox) {
        // Use setProperty for Safari
        container.style.setProperty('background', 'white')
        container.style.setProperty('backgroundColor', 'white')
        container.style.setProperty('background-color', 'white')
        container.style.setProperty('color', 'black')
        container.style.background = 'white'
        container.style.backgroundColor = 'white'
        container.style.color = 'black'
      }

      doc.body.appendChild(container)

      selection = window.getSelection() as Selection
      selection?.removeAllRanges()
      range = doc.createRange()
      range?.selectNodeContents(container)
      selection?.addRange(range)

      doc.execCommand('copy')
      doc.body.removeChild(container)
    } catch (e) {
      console.log(e)
    }
  }

  const onCopyNote = async () => {
    if (isWeb) {
      const source = `<strong>${getTitleAsString().split('\n')[0]}</strong><br/>${parsedValueLines
        .map(transformCMSTextToString)
        .join('<br/>')}<br/>${parsedDescription
          .map((line, index) => {
            const transformed = transformCMSTextToString(line)

            // Add an extra line for titles, except the first line
            const isBoldTitle = !!line.values?.filter((v, ix) => v.fontWeight === "bold" && ix === 0)?.length
            if (isBoldTitle) return `${index !== 0 ? '<br/>' : ''}` + `<strong>${transformed}</strong>`

            return transformed
          })
          .join('<br/>')}`

      await CopyStyledNote(source)
    } else {
      const result = `${getTitleAsString()}${parsedValueLines
        .map(transformCMSTextToString)
        .join('\r\n')}\r\n${parsedDescription
          .map((line, index) => {
            const transformed = transformCMSTextToString(line)

            // Add an extra line for titles, except the first line
            const isBoldTitle = !!line.values?.filter((v, ix) => v.fontWeight === "bold" && ix === 0)?.length
            if (isBoldTitle) return `${index !== 0 ? '\r\n' : ''}` + transformed

            return transformed
          })
          .join('\r\n')}`
      await nativeCopyHandler(result)
    }
    show({ message: 'Copied to clipboard', status: 'success' })

  }

  const getTitleAsString = () =>
    `${title} = ${parsedTitleValue} ${parsedTitlePercentage ? `| ${parsedTitlePercentage}` : ''
    }\r\n`

  return (
    <VStack bg="text.700" w="100%" p="6">
      <HStack>
        <HStack alignItems="center" flexWrap="wrap" flex="8">
          <Text fontSize="lg" mr="2" color="text.50" fontWeight="bold">
            {title} =
          </Text>
          <HStack alignItems="center">
            <Text color="primary.400" fontSize="2xl" fontWeight="bold">
              {parsedTitleValue}
            </Text>
            {percentageValue && (
              <>
                <Text fontSize="lg" mx="2" color="text.50" fontWeight="bold">
                  |
                </Text>
                <Text fontSize="2xl" color="primary.400" fontWeight="bold">
                  {parsedTitlePercentage}
                </Text>
              </>
            )}
          </HStack>
        </HStack>
        <HStack alignItems="flex-start" flex="1" justifyContent="flex-end">
          <View>
            <Button
              m="0"
              p="0"
              color="primary.400"
              leftIcon={<Icon name="copyoutlined" size="6" />}
              colorScheme="primary"
              variant="link"
              onPress={onCopyNote}>
              {isWeb && !isMobileWeb && 'Copy Note'}
            </Button>
          </View>
        </HStack>
      </HStack>
      <Collapsable>
        <VStack ref={dataContainer}>
          {parsedValueLines.map((props, index) => (
            <CMSText
              {...props}
              key={getKey(`${title}-parsedValueLines`, index)}
            />
          ))}
          {parsedDescription.map((props, index) => (
            <CMSText
              {...props}
              key={getKey(`${title}-parsedDescription`, index)}
            />
          ))}
        </VStack>
      </Collapsable>
    </VStack>
  )
}

export default CalculatorNote
