import React from 'react'
import { HStack, VStack, Text, Divider, View, ScrollView, Stack } from 'native-base'

import Icon from '../../Icon'
import type { IconName } from '../../Icon/types.icon'
import CMSText, { ICMSTextProps } from '../CMSText'
import { IColors } from 'native-base/lib/typescript/theme/base/colors'

interface IconGroup {
  icon: IconName
  name: string
}

export interface IPlanFeaturesProps {
  title: ICMSTextProps
  isMobile?: boolean
}

interface IFeaturesGroup {
  color: IColors | string
  title: string
  data: IconGroup[]
}

const SNAPSHOT_FEATURES: IconGroup[] = [
  { name: 'Property\nDetails', icon: 'housingdata' },
  { name: 'Jurisdiction', icon: 'jurisdictionassignment' },
  { name: 'Sales\nTax', icon: 'salestax' }
]

const SUPPORTING_DOCUMENTS_FEATURES: IconGroup[] = [
  { name: 'Building Code\nDetail', icon: 'detail' },
  { name: 'Manufacturer\nRequirements', icon: 'manufacturerrequirements' },
  { name: 'Building\nDesign Criteria', icon: 'house' }
]

const ESTIMATING_TOOLS: IconGroup[] = [
  { name: 'Ice & Water\nShield', icon: 'iws' },
  { name: 'Roof\nVentilation', icon: 'vent' },
  { name: 'Shingle\nWaste', icon: 'waste' },
  { name: 'Permit\nFee', icon: 'permitfee' }
]

const FeatureGroup = ({ color, title, data = [] }: IFeaturesGroup) => (
  <VStack alignItems="center" space={6}>
    <HStack justifyContent="flex-start" space={4}>
      {data.map(({ name, icon }) => (
        <VStack
          justifyContent="flex-start"
          alignItems="center"
          key={name}
          minW="92px">
          <Icon size="8" name={icon} color={color} />
          <Text fontSize="xs" textAlign="center" color="neutral.300">
            {name}
          </Text>
        </VStack>
      ))}
    </HStack>
    <Stack width="100%" alignItems="center" space={3}>
      <Divider bg={color} />
      <Text fontSize="sm" fontWeight="semibold" color={color}>
        {title}
      </Text>
    </Stack>

  </VStack>
)

const PlanFeatures = ({ title, isMobile = false }: IPlanFeaturesProps) => (
  <VStack flex="1" w="100%" overflow="hidden" space={6}>
    <CMSText mb="6" fontWeight="bold" {...title} />
    <ScrollView
      // contentContainerStyle={{ flexGrow: 1 }}
      _web={{
        flex: 1,
        w: '100%',
        contentContainerStyle: isMobile
          ? { flex: 1 }
          : {
            flexDirection: 'row',
            // alignItems: 'center',
            // justifyContent: 'center',
            flex: 1
          }
      }}
      // flex="1"
      // w="100%"
      showsHorizontalScrollIndicator={false}
      horizontal>
      <Stack direction="row" space={12}>
        <FeatureGroup
          color="cyan.400"
          title="Property Quickview"
          data={SNAPSHOT_FEATURES}
        />
        <FeatureGroup
          color="amber.400"
          title="Reports"
          data={SUPPORTING_DOCUMENTS_FEATURES}
        />
        <FeatureGroup
          color="uncategorized.900"
          title="Estimating Tools"
          data={ESTIMATING_TOOLS}
        />
      </Stack>

    </ScrollView>
  </VStack>
)

export default PlanFeatures
