import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

const IrcSteepIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="56" height="56" viewBox="0 0 56 56"  {...props}>
      <G clipPath="url(#clip0_5403_24743)">
        <Path
          d="M9.33337 21.0856V52.2489H27.2223L27.2576 45.3788H32.6314L32.596 52.2489L37.5455 52.2843L37.5808 40.421H22.3435L22.3081 47.2911H14.2829V24.2374L41.7172 11.4888V52.2489H46.6667V3.7334L9.33337 21.0856Z"
          fill={fillColor}
        />
        <Path
          d="M18.7373 27.035C18.7373 27.035 18.6313 27.035 18.5959 27.1058C18.5959 27.1058 18.5605 27.1766 18.5605 27.2475V36.0652C18.5605 36.0652 18.5605 36.1715 18.5959 36.2069C18.6313 36.2423 18.6666 36.2423 18.7373 36.2423H20.8232C20.8232 36.2423 20.9292 36.2423 20.9646 36.2069C20.9646 36.2069 20.9999 36.136 20.9999 36.0652V27.2475C20.9999 27.2475 20.9999 27.1412 20.9646 27.1058C20.9292 27.0704 20.8939 27.035 20.8232 27.035H18.7373Z"
          fill={fillColor}
        />
        <Path
          d="M29.202 31.497C29.4848 31.072 29.6262 30.5408 29.6262 29.9742C29.6262 29.4076 29.4848 28.8764 29.2373 28.4515C28.9899 28.0265 28.6363 27.6724 28.1767 27.4245C27.7171 27.1766 27.1868 27.0704 26.6212 27.0704H22.6262C22.6262 27.0704 22.5202 27.0704 22.4848 27.1412C22.4848 27.1412 22.4495 27.212 22.4495 27.2828V36.1006C22.4495 36.1006 22.4495 36.2069 22.4848 36.2423C22.5202 36.2777 22.5555 36.2777 22.6262 36.2777H24.7121C24.7121 36.2777 24.8181 36.2777 24.8535 36.2423C24.8535 36.2423 24.8889 36.1714 24.8889 36.1006V32.8072C24.8889 32.8072 24.8889 32.7364 24.9596 32.7364H25.5252C25.5252 32.7364 25.5959 32.7364 25.5959 32.7718L27.0454 36.136C27.0454 36.136 27.1515 36.2777 27.2575 36.2777H29.4495C29.4495 36.2777 29.5555 36.2777 29.5909 36.2423C29.5909 36.2423 29.6262 36.1714 29.6262 36.136C29.6262 36.136 29.6262 36.1006 29.6262 36.0298L27.9646 32.5593C27.9646 32.5593 27.9646 32.4885 28 32.4531C28.4949 32.2406 28.8838 31.9219 29.1666 31.497H29.202ZM26.904 30.6116C26.904 30.6116 26.5151 30.8241 26.2323 30.8241H24.9949C24.9949 30.8241 24.9242 30.8241 24.9242 30.7533V29.2305C24.9242 29.2305 24.9242 29.1597 24.9949 29.1597H26.2323C26.5151 29.1597 26.7272 29.2305 26.904 29.3722C27.0808 29.5138 27.1515 29.7263 27.1515 29.9742C27.1515 30.2221 27.0808 30.4346 26.904 30.5762V30.6116Z"
          fill={fillColor}
        />
        <Path
          d="M33.5151 29.3722C33.7272 29.1598 34.01 29.0535 34.3636 29.0535C34.7171 29.0535 34.9999 29.1598 35.2121 29.3722C35.4242 29.5847 35.5302 29.868 35.5302 30.2222C35.5302 30.3284 35.5656 30.3638 35.6717 30.3638H35.707L37.7929 30.2222C37.7929 30.2222 38.005 30.1513 38.005 30.0805C38.005 29.4431 37.8636 28.8765 37.5454 28.4161C37.2272 27.9557 36.803 27.5662 36.2727 27.3183C35.7424 27.0704 35.106 26.9288 34.3636 26.9288C33.6212 26.9288 33.0201 27.0704 32.4545 27.3537C31.8888 27.637 31.4646 28.0266 31.1818 28.5223C30.8636 29.0181 30.7222 29.6201 30.7222 30.293V32.9489C30.7222 33.6218 30.8636 34.2238 31.1818 34.7196C31.4646 35.2154 31.9242 35.6403 32.4545 35.9236C33.0201 36.2069 33.6212 36.3486 34.3636 36.3486C35.106 36.3486 35.707 36.2069 36.2727 35.959C36.8383 35.6757 37.2626 35.3216 37.5454 34.8258C37.8282 34.33 38.005 33.7988 38.005 33.1614C38.005 33.0906 37.9343 33.0198 37.7929 33.0198L35.707 32.9135C35.707 32.9135 35.601 32.9135 35.5656 32.9135C35.5656 32.9135 35.5302 32.9844 35.5302 33.0198C35.5302 33.3739 35.4242 33.6572 35.2121 33.8697C34.9999 34.0821 34.7171 34.1884 34.3636 34.1884C34.01 34.1884 33.7272 34.0821 33.5151 33.8697C33.303 33.6572 33.1969 33.3739 33.1969 33.0198V30.1867C33.1969 29.8326 33.303 29.5493 33.5151 29.3368V29.3722Z"
          fill={fillColor}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5403_24743">
          <Rect width="56" height="56" fill={fillColor} />
        </ClipPath>
      </Defs>
    </Icon>
  )
}
export default IrcSteepIcon
