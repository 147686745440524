import React from 'react'
import { Icon, IIconProps } from 'native-base'
import {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop
} from 'react-native-svg'

const ProPlanIcon = (props: IIconProps) => (
  <Icon {...props} width="127.341" height="55.475" viewBox="0 0 127.341 55.475">
    <Defs>
      <LinearGradient
        id="linear-gradient-proplan"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <Stop offset="0" stopColor="#18974a" />
        <Stop offset="1" stopColor="#13800c" />
      </LinearGradient>
      <ClipPath id="clip-path-proplan">
        <Rect
          id="Rectangle_54"
          data-name="Rectangle 54"
          width="127.341"
          height="55.475"
          fill="#c3dae6"
        />
      </ClipPath>
    </Defs>
    <G
      id="Illustr_Houses"
      data-name="Illustr/Houses"
      transform="translate(0 0)">
      <Path
        id="Front_of_Houses"
        data-name="Front of Houses"
        d="M1684.1,10923.652V10886.5h42.032v37.152Zm-51.932,0v-27.368h34.205v27.368Z"
        transform="translate(-1604.784 -10869.533)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        fill="url(#linear-gradient-proplan)"
      />
      <Path
        id="Left_Sides"
        data-name="Left Sides"
        d="M1687.773,10940.62v-27.367H1680.6v-.014l7.175,0-7.175-9.764v0l12.452-16.966,12.449,16.966v37.152Zm-55.6,0v-27.367l10.626-14.662,10.771,14.662v27.367Z"
        transform="translate(-1626.18 -10886.501)"
        fill="#0a4b0f"
      />
      <G id="Roofs">
        <Path
          id="Path_59"
          data-name="Path 59"
          d="M50.594,0,63.043,16.967h42.032L92.625,0Z"
          transform="translate(16.275)"
          fill="#6ab329"
        />
        <Path
          id="Path_62"
          data-name="Path 62"
          d="M46.789,9.146H12.57L23.343,23.81l34.206-.019Z"
          transform="translate(4.043 2.942)"
          fill="#6ab329"
        />
      </G>
      <Rect
        id="Baseline"
        width="127.341"
        height="1.356"
        transform="translate(0 54.119)"
        fill="#0a4b10"
      />
      <G id="Hail_Wind" data-name="Hail &amp; Wind" transform="translate(0 0)">
        <G
          id="Group_31"
          data-name="Group 31"
          transform="translate(0 0)"
          clipPath="url(#clip-path-proplan)">
          <Path
            id="Path_63"
            data-name="Path 63"
            d="M24.213,13.694a2.123,2.123,0,1,0,2.123,2.123,2.123,2.123,0,0,0-2.123-2.123"
            transform="translate(7.106 4.405)"
            fill="#c3dae6"
          />
          <Rect
            id="Rectangle_51"
            data-name="Rectangle 51"
            width="1.322"
            height="18.196"
            transform="matrix(0.806, -0.592, 0.592, 0.806, 17.13, 2.276)"
            fill="#c3dae6"
          />
          <Path
            id="Path_64"
            data-name="Path 64"
            d="M16.015,19.5a2.123,2.123,0,1,0,2.123,2.123A2.123,2.123,0,0,0,16.015,19.5"
            transform="translate(4.469 6.271)"
            fill="#c3dae6"
          />
          <Rect
            id="Rectangle_52"
            data-name="Rectangle 52"
            width="1.322"
            height="18.196"
            transform="translate(6.326 10.243) rotate(-36.303)"
            fill="#c3dae6"
          />
          <Path
            id="Path_65"
            data-name="Path 65"
            d="M35.13,13.833a2.123,2.123,0,1,0,2.123,2.123,2.123,2.123,0,0,0-2.123-2.123"
            transform="translate(10.617 4.45)"
            fill="#c3dae6"
          />
          <Rect
            id="Rectangle_53"
            data-name="Rectangle 53"
            width="1.321"
            height="18.196"
            transform="matrix(0.806, -0.592, 0.592, 0.806, 31.787, 2.519)"
            fill="#c3dae6"
          />
          <Path
            id="Path_66"
            data-name="Path 66"
            d="M78.533,41.768a8.344,8.344,0,0,1-6.661-3.3L43.9,1.627l1.053-.8L72.926,37.671a7.028,7.028,0,1,0,1.348-9.848l-.8-1.053a8.351,8.351,0,1,1,5.059,15"
            transform="translate(14.121 0.266)"
            fill="#c3dae6"
          />
          <Path
            id="Path_67"
            data-name="Path 67"
            d="M55.652,24.316,37.837.848l-1.053.8L54.6,25.114A4.237,4.237,0,1,1,47.027,27.1a4.189,4.189,0,0,1,1.345-2.531L47.589,23.5a5.541,5.541,0,1,0,8.064.812"
            transform="translate(11.832 0.273)"
            fill="#c3dae6"
          />
        </G>
      </G>
    </G>
  </Icon>
)
export default ProPlanIcon
