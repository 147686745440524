import React from 'react'
import FormCustom, { IFormCustomProps } from './Fields/Custom.field'
import FormSelect, { IFormSelectProps } from './Fields/Select.field'
import FormInput, { IFormInputProps } from './Fields/Input.field'
import FormPassword, {
  IFormPasswordProps,
  IPasswordRules
} from './Fields/Password.field'
import FormTextArea, { IFormTextAreaProps } from './Fields/TextArea.field'
import FormOptionToggle, { IFormOptionToggleProps } from './Fields/OptionToggle.field'

type FormFieldGeneric<T> = React.ForwardRefExoticComponent<
  IFormTextAreaProps & React.RefAttributes<T>
>

export interface IFormField {
  Password: typeof FormPassword
  TextArea: FormFieldGeneric<IFormTextAreaProps>
  Input: FormFieldGeneric<IFormInputProps>
  Select: typeof FormSelect
  Custom: FormFieldGeneric<IFormCustomProps>
  OptionToggle: typeof FormOptionToggle 
}

export default {
  Password: FormPassword,
  Input: FormInput,
  Select: FormSelect,
  TextArea: FormTextArea,
  Custom: FormCustom,
  OptionToggle: FormOptionToggle
} as IFormField

export * from './Fields/Custom.field'
export * from './Fields/Select.field'
export * from './Fields/Input.field'
export * from './Fields/Password.field'
export * from './Fields/TextArea.field'
export * from './Fields/OptionToggle.field'
