import { useTheme } from 'native-base'
import { useCallback, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

const BORDER_RADIUS = 4

export const isiOS = Platform.OS === 'ios'

const createStyles = (colors: any, sizes: any, disabled = false) => ({
  ...styles,
  ...(isiOS
    ? {
        inputIOS: {
          ...styles.input,
          fontSize: sizes.md,
          color: disabled ? colors.text['500'] : colors.text['50'],
          backgroundColor: disabled
            ? colors.background['700']
            : colors.background['600']
        }
      }
    : {
        inputAndroid: {
          ...styles.input,
          fontSize: sizes.md,
          color: disabled ? colors.text['500'] : colors.text['50'],
          backgroundColor: disabled
            ? colors.background['700']
            : colors.background['600']
        },
        iconContainer: styles.iconContainer,
        headlessAndroidPicker: styles.headlessAndroidPicker
        // headlessAndroidContainer:{ backgroundColor: colors.text['500'] },
      }),
  chevronDown: {
    borderColor: colors.primary['500']
  },
  chevronUp: {
    borderColor: colors.primary['500']
  },
  done: {
    color: colors.primary['500']
  },
  modalViewMiddle: {
    ...styles.modalViewMiddle,
    backgroundColor: colors.background['500']
  },
  modalViewBottom: {
    ...styles.modalViewBottom,
    backgroundColor: colors.background['500']
  },
  viewContainer: {
    ...styles.viewContainer,
    borderRadius: BORDER_RADIUS,
    backgroundColor: colors.background['600']
  }
})

const useNativePickerStyles = () => {
  const { colors, fontSizes } = useTheme() as any

  const getStyles = useCallback(
    (isDisabled: boolean = false) => {
      const pickerContainerStyleColors = Platform.select({
        ios: {
          flex: 1,
          position: 'relative',
          top: -15,
          backgroundColor: colors.background['500']
        }
      })

      return {
        style: createStyles(colors, fontSizes, isDisabled),
        pickerProps: {
          ...(isiOS ? { style: pickerContainerStyleColors } : {}),
          itemStyle: {
            ...styles.pickerItemStyle,
            color: colors.text['50']
          }
        }
      }
    },
    [colors, fontSizes],
  )

  return { getStyles }
}

export default useNativePickerStyles

const styles = StyleSheet.create({
  // this comes from RNPickerSelect default styles
  headlessAndroidPicker: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: 'transparent',
    opacity: 0
  },
  // custom styles
  pickerItemStyle: {
    fontFamily: 'Barlow-SemiBold'
  },
  viewContainer: {
    flex: 1,
    borderRadius: BORDER_RADIUS,
    marginBottom: 5
  },
  modalViewMiddle: {
    borderTopWidth: 0,
    height: 55
  },
  modalViewBottom: {
    paddingTop: 15,
    height: '32%'
  },
  iconContainer: {
    marginTop:0,
    marginRight: 16,
    height:'100%',
    justifyContent: 'center'
  },
  input: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: BORDER_RADIUS
  }
})
