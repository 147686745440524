import React from 'react'
import { HStack, Switch, Text } from 'native-base'

export interface IBillingSwitchProps {
  checked?: boolean
  onChange: () => void
  isDisabled?: boolean
}

const BillingSwitch = ({
  checked = false,
  onChange,
  isDisabled = false
}: IBillingSwitchProps) => (
  <HStack alignItems="center" justifyContent="flex-end">
    <Text
      fontWeight="semibold"
      color={checked ? 'text.500' : 'primary.500'}
      fontSize="2xs">
      Monthly
    </Text>
    <Switch
      isDisabled={isDisabled}
      onToggle={onChange}
      isChecked={checked}
      colorScheme="primary"
      size="sm"
    />
    <Text
      fontWeight="semibold"
      color={checked ? 'primary.500' : 'text.500'}
      fontSize="2xs">
      Annual
    </Text>
  </HStack>
)

export default BillingSwitch
