import { StyleSheet } from 'react-native'
import { Box, HStack, Pressable, Stack, Text, View } from 'native-base'

import Icon from '../../Icon'

const tools: Record<string, any> = {
  iws_calculation: {
    type: 'iws_calculation',
    title: 'Ice and Water Shield Calculator',
    description:
      'Identify the necessary Ice & Water Shield membrane to be included as well as the associative costs.',
    icon: 'iwsnew'
  },
  ventilation_calculation: {
    type: 'ventilation_calculation',
    title: 'Ventilation Calculator',
    description:
      'Calculate the necessary ventilation needed based on the complexity of the roof and how much ventilation you need to stay up to code.',
    icon: 'ventilationnew'
  },
  waste_calculation: {
    type: 'waste_calculation',
    title: 'Waste Calculator',
    description:
      'Determine the amount of shingle waste on any roofing project and quickly copy the results into your estimate.',
    icon: 'wastenew'
  }
}

export type IToolName = keyof typeof tools

export interface ITool {
  toolName: IToolName
  title?: string
  description?: string
  action?: () => void
}

const ToolCard = ({ toolName, title, description, action }: ITool) => {
  const internalStyles = StyleSheet.create({
    container: {
      borderWidth: 1,
      borderRadius: 16,
      borderColor: '#333440',
      backgroundColor: '#191A27',
      paddingTop: 20,
      paddingRight: 16,
      paddingBottom: 16,
      paddingLeft: 20,
      height: '100%'
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 24
    },
    description: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: 17,
      color: '#AFAFAF'
    }
  })

  return (
    <Pressable w="100%" h="100%" onPress={action}>
      <HStack style={internalStyles.container}>
        <View paddingRight="4">
          <Box w="26px" h="26px">
            <Icon name={tools[toolName].icon} size="100%" />
          </Box>
        </View>
        <Stack flex="1">
          <Text style={internalStyles.title}>
            {title ?? tools[toolName].title}
          </Text>
          <Text style={internalStyles.description}>
            {description ?? tools[toolName].description}
          </Text>
          <Box marginTop="auto">
            <HStack marginTop="2" paddingY="2" alignItems="center">
              <Text color="#1AA7FF" fontSize="14" lineHeight="17">
                Calculate
              </Text>
              <Box marginLeft="3">
                <Box w="9px" h="14px">
                  <Icon name="chevronrightnew" size="100%" />
                </Box>
              </Box>
            </HStack>
          </Box>
        </Stack>
      </HStack>
    </Pressable>
  )
}

export default ToolCard
