import { SvgProps } from 'react-native-svg'
import { ColorMode, View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import OCCLogoLinearSVG from './occode/LogoLinear'
import OCCLogoLinearLightSVG from './occode/LogoLinearLight'
import OCCLogoSoloLightSVG from './occode/LogoSoloLight'
import OCCLogoSoloSVG from './occode/LogoSolo'
import OCCLogoStackedSVG from './occode/LogoStacked'
import OCCLogoStackedLightSVG from './occode/LogoStackedLight'
import OCCLogoStackedCenteredSVG from './occode/LogoStackedCentered'
import OCCLogoStackedCenteredLightSVG from './occode/LogoStackedCenteredLight'
import OCDLogoStackedSVG from './ocdata/OCDLogoStacked'

type Brand = 'occode' | 'ocdata'
type LogoVariants = 'linear' | 'stacked' | 'stacked-centered' | 'solo'

type SvgComponent = ((props: SvgProps) => JSX.Element) | null
type Sources = {
  [key in NonNullable<ColorMode>]: Record<LogoVariants, SvgComponent>
}
const OCCLogoSources: Sources = {
  light: {
    linear: OCCLogoLinearLightSVG,
    stacked: OCCLogoStackedLightSVG,
    'stacked-centered': OCCLogoStackedCenteredLightSVG,
    solo: OCCLogoSoloLightSVG
  },
  dark: {
    linear: OCCLogoLinearSVG,
    stacked: OCCLogoStackedSVG,
    'stacked-centered': OCCLogoStackedCenteredSVG,
    solo: OCCLogoSoloSVG
  }
}

// TODO: Add OCD Logo Variants
// Ask design for the rest of the variants
const OCDLogoSources: Sources = {
  light: {
    linear: null,
    stacked: null,
    'stacked-centered': null,
    solo: null
  },
  dark: {
    linear: null,
    stacked: OCDLogoStackedSVG,
    'stacked-centered': null,
    solo: null
  }
}

const BrandLogos = {
  occode: OCCLogoSources,
  ocdata: OCDLogoSources
}

export interface LogoProps extends IViewProps {
  brand?: 'occode' | 'ocdata'
  variant: LogoVariants
  colorMode?: ColorMode
}

const Logo = ({
  brand = 'occode',
  variant,
  ...props
}: LogoProps): JSX.Element => {
  const colorMode = props.colorMode ?? 'dark'

  const LogoSVG = BrandLogos[brand][colorMode][variant]

  return (
    <View {...props}>
      { LogoSVG ? <LogoSVG /> : null }
    </View>
  )
}

export default Logo
