import React from 'react'
import { Icon } from 'native-base'
import { NATIVE_ICONS_NAME_MAP } from './nativeTransformer.antd'

let outlineMap: Record<string, string> = {}
let fillMap: Record<string, string> = {}
let IconOutline: any
let IconFill: any
try {
  const {
    IconOutline: IconOutlineImport,
    IconFill: IconFillImport
  } = require('@ant-design/icons-react-native')
  IconOutline = IconOutlineImport
  IconFill = IconFillImport
  const {
    outlineGlyphMap
  } = require('@ant-design/icons-react-native/lib/outline')
  outlineMap = outlineGlyphMap
  const { fillGlyphMap } = require('@ant-design/icons-react-native/lib/fill')
  fillMap = fillGlyphMap
  // do stuff
} catch (ex) {
  IconOutline = () => {}
  IconFill = () => {}
}

const FILLED_SUFFIX = 'filled'
const OUTLINED_SUFFIX = 'outlined'
// const TWO_TONE_SUFFIX = 'twotone'

const getCleanedName = (name: string, suffix: string) => {
  const cleanedName = name.slice(0, -suffix.length)
  return cleanedName
}

const NativePicker = React.memo(({ name: propName, ...props }: any) => {
  let cleanedName = propName
  let Component: any = IconFill
  if (propName.endsWith(FILLED_SUFFIX)) {
    cleanedName = getCleanedName(propName, FILLED_SUFFIX)
    Component = IconFill
  } else if (propName.endsWith(OUTLINED_SUFFIX)) {
    cleanedName = getCleanedName(propName, OUTLINED_SUFFIX)
    Component = IconOutline
  } else {
    if (fillMap[cleanedName]) Component = IconFill
    else if (outlineMap[cleanedName]) Component = IconOutline
    else cleanedName = 'questioncircle'
  }
  const name = NATIVE_ICONS_NAME_MAP[cleanedName]
  return <Icon as={<Component name={name} />} {...props} />
})

export default NativePicker
