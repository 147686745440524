import React, { useRef } from 'react'
import {
  useToast,
  CheckCircleIcon,
  IconButton,
  CloseIcon,
  WarningTwoIcon,
  InfoIcon,
  Text,
  View,
  IToastProps as INativeBaseToastProps,
  HStack,
  VStack,
  Button
} from 'native-base'
export interface IToastProps
  extends Pick<INativeBaseToastProps, 'placement'>,
  Pick<INativeBaseToastProps, 'status'>,
  Pick<INativeBaseToastProps, '_web'>,
  Pick<INativeBaseToastProps, 'id'>,
  Pick<INativeBaseToastProps, 'title'> {
  message: string
  title?: string
  onPress?: () => void
  buttonLabel?: string
}

const SUCCESS_STATUS = 'success'
const CELEBRATE_STATUS = 'celebrate'
const ERROR_STATUS = 'error'
const INFO_STATUS = 'info'

const useOCCToast = () => {
  const { show, ...toast } = useToast()
  const toastRef = useRef(null)

  const close = () => toast.close(toastRef.current)

  const customShow = ({
    message,
    status,
    placement,
    title,
    _web = {},
    buttonLabel,
    onPress
  }: IToastProps) => {
    toastRef.current = show({
      placement,

      render: () => (
        <View minW="100%" p="2" alignItems="center">
          <HStack
            bg={status === 'info' ? 'cyan.600' : `${status}.500`}
            borderRadius={4}
            p="4"
            pr="2"
            maxWidth={500}
            alignItems={title ? 'flex-start' : 'center'}
            _web={{
              width: 371,
              position: 'absolute',
              left: 5,
              bottom: { base: 900, lg: 250 },
              mb: 5,
              ..._web
            }}>
            <View>
              {status === SUCCESS_STATUS && (
                <CheckCircleIcon size="xs" color="text.50" mr={4} />
              )}
              {status === CELEBRATE_STATUS && (
                <Text mr={4}>{String.fromCodePoint(0x1f389)}</Text>
              )}
              {status === ERROR_STATUS && (
                <WarningTwoIcon color="text.50" size="xs" mr={4} />
              )}
              {status === INFO_STATUS && (
                <WarningTwoIcon color="text.50" size="xs" mr={4} />
              )}
            </View>
            <VStack flex={1} space={2}>
              {title && (
                <Text
                  color="text.50"
                  fontWeight="700"
                  fontSize="md"
                  lineHeight="19.2">
                  {title}
                </Text>
              )}
              <Text color="text.50" fontSize="md" lineHeight="19.2">
                {message}
              </Text>
              {onPress && <Button onPress={onPress}>{buttonLabel}</Button>}
            </VStack>
            <View pl="2">
              <IconButton
                onPress={close}
                icon={<CloseIcon size="12px" color="text.50" />}
              />
            </View>
          </HStack>
        </View>
      )
    })
  }

  return {
    ...toast,
    show: customShow
  }
}
export default useOCCToast
