import React from 'react'
import _ from 'lodash'
import { Icon, IIconProps, useTheme } from 'native-base'
import {
  G,
  Rect,
  Line,
  Polyline,
  Circle,
  Defs,
  Use,
  Pattern,
  Image,
  Path
} from 'react-native-svg'

const Drone = (props: IIconProps) => {
  const { colors } = useTheme() as any
  const gold = colors.secondary['400']
  const white = colors.text['50']
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <G>
        <Path
          d="M3.133,6.266A3.125,3.125,0,0,0,4.3,6.043l-.049-.07-.341-.621A2.35,2.35,0,1,1,5.351,3.91l.622.337.07.049a3.134,3.134,0,1,0-2.91,1.97"
          transform="translate(0 0)"
          fill={gold}
        />
        <Path
          d="M.294,4.247l.622-.336a2.35,2.35,0,1,1,1.44,1.439l-.337.622-.049.07A3.131,3.131,0,1,0,.223,4.3l.07-.049"
          transform="translate(12.533)"
          fill={gold}
        />
        <G transform="translate(0 12.533)">
          <Path d="M0,0H6.266V6.267H0Z" fill="none" />
          <G>
            <Path
              d="M5.973,2.019l-.621.339A2.35,2.35,0,1,1,3.91.915L4.247.294,4.3.223A3.131,3.131,0,1,0,6.043,1.97l-.07.049"
              fill={gold}
            />
          </G>
        </G>
        <Path
          d="M3.133,0A3.124,3.124,0,0,0,1.97.223l.049.07.337.622A2.35,2.35,0,1,1,.915,2.357L.294,2.019.223,1.97A3.134,3.134,0,1,0,3.133,0"
          transform="translate(12.533 12.533)"
          fill={gold}
        />
        <Path
          d="M12.533,13.309h-.007L10.02,11.52a5.125,5.125,0,0,0-5.955,0L1.56,13.309H1.553a.777.777,0,1,1-.776-.776v-.007L2.566,10.02a5.122,5.122,0,0,0,0-5.955L.777,1.56V1.553A.777.777,0,1,1,1.553.777H1.56L4.065,2.566a5.122,5.122,0,0,0,5.955,0L12.526.777h.007a.777.777,0,1,1,.776.776V1.56L11.52,4.065a5.125,5.125,0,0,0,0,5.955l1.79,2.506v.007a.777.777,0,1,1-.776.776Z"
          transform="translate(2.357 2.357)"
          fill={white}
        />
      </G>
    </Icon>
  )
}
export default Drone
