import React from 'react'
import { Button, ChevronLeftIcon, HStack, IconButton } from 'native-base'
import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button'
import { IStackProps } from 'native-base/lib/typescript/components/primitives/Stack'
import { ColorType } from 'native-base/lib/typescript/components/types'

export interface IBackButtonProps extends IButtonProps {
  text?: string
  iconColor?: ColorType
  color: ColorType
  onPress: () => void
}

const stackProps = {
  style: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap'
  }
} as IStackProps

const customWebProps = {
  _text: {
    style: {
      opacity: 0.8,
      letterSpacing: 1.07,
      fontWeight: '600'
    }
  }
} as IButtonProps

export default ({
  text = 'BACK',
  color = 'text.50',
  iconColor = 'primary.500',
  fontSize = 'xs',
  onPress,
  ...props
}: IBackButtonProps) => (
  <HStack alignItems="center" justifyContent="center">
    <IconButton
      onPress={onPress}
      p={0}
      _web={{ pt: 1 }}
      icon={<ChevronLeftIcon size="sm" color={iconColor} />}
    />
    <Button
      {...props}
      p={0}
      onPress={onPress}
      _stack={stackProps}
      _web={customWebProps}
      fontSize={fontSize}
      variant="link"
      _text={{ color }}>
      {text && text.toUpperCase()}
    </Button>
  </HStack>
)
