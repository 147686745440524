import { Platform } from 'react-native'
import React, { FunctionComponent } from 'react'
import {
  IconButton,
  Tooltip,
  Popover,
  InfoIcon,
  IButtonProps,
  IIconButtonProps
} from 'native-base'
import { INativeBaseTooltipProps } from './FormTooltipInterface'
import { isWeb } from '../../../utils'
import Icon from '../../Icon'

interface WebTooltipProps extends IIconButtonProps { }

interface NativeTooltipProps extends IButtonProps { }

export interface ITooltipProps extends Partial<INativeBaseTooltipProps> {
  label?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  triggerProps?: WebTooltipProps | NativeTooltipProps
  placementMobileWeb?: 'top' | 'bottom'
  mobileWeb?: boolean
  style?: any,
  customIcon?: any
}

const WebTooltip = ({
  label = '',
  placement = 'right',
  placementMobileWeb = 'bottom',
  triggerProps: externalTriggerProps = {},
  style: contentStyle = {
    content: {
      backgroundColor: '#333440',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      width: 316,
      minHeight: 33
    },
    _text: {
      color: '#ffffff',
      fontWeight: 500,
      fontSize: 14
    }
  },
  mobileWeb = false,
  customIcon,
  ...props
}: ITooltipProps) => {
  if (mobileWeb) {
    return (
      <Popover
        {...props}
        placement={placementMobileWeb}
        trapFocus
        trigger={triggerProps => (
          <IconButton
            p={0}
            ml="2"
            mt="1.5px"
            mb="1.5px"
            mr="1.5px"
            {...(externalTriggerProps as NativeTooltipProps)}
            icon={customIcon ||
              <Icon
                name="infocirclefilled"
                size={5}
                color="cyan.200"
                isAntDesign={true}
              />
            }
            {...triggerProps}
          />
        )}>
        <Popover.Content>
          <Popover.Body style={contentStyle.content} _text={contentStyle._text}>
            {label}
          </Popover.Body>
        </Popover.Content>
      </Popover>
    )
  }
  return (
    <Tooltip
      {...props}
      placement={placement}
      style={contentStyle.content}
      _text={contentStyle._text}
      label={label}>
      <IconButton
        p={0}
        ml="2"
        mt="1.5px"
        mb="1.5px"
        mr="1.5px"
        focusable={false}
        {...(externalTriggerProps as WebTooltipProps)}
        icon={customIcon ||
          <Icon
            name="infocirclefilled"
            size={5}
            color="cyan.200"
            isAntDesign={true}
          />
        }
      />
    </Tooltip>
  )
}

const NativeTooltip = ({
  label = '',
  placement = 'bottom',
  triggerProps: externalTriggerProps = {},
  ...props
}: ITooltipProps) => (
  <Popover
    {...props}
    placement={placement}
    trapFocus
    trigger={triggerProps => (
      <IconButton
        p={0}
        ml={1}
        {...(externalTriggerProps as NativeTooltipProps)}
        icon={<InfoIcon size="4" color="primary.500" />}
        {...triggerProps}
      />
    )}>
    <Popover.Content>
      <Popover.Arrow />
      <Popover.Body>{label}</Popover.Body>
    </Popover.Content>
  </Popover>
)

const CustomTooltip: FunctionComponent<ITooltipProps> = isWeb
  ? WebTooltip
  : NativeTooltip

export default CustomTooltip
