import React from 'react'
import { ColorValue, Platform } from 'react-native'
import {
  AddIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CircleIcon,
  CloseIcon,
  HamburgerIcon,
  Icon as NativeBaseIcon,
  IIconProps as NativeBaseIIconProps,
  InfoIcon,
  InfoOutlineIcon,
  MinusIcon,
  MoonIcon,
  QuestionIcon,
  QuestionOutlineIcon,
  SearchIcon,
  SunIcon,
  useToken,
  WarningIcon,
  WarningTwoIcon
} from 'native-base'
import {
  ANT_DESIGN_ICON_NAMES,
  AntDesignIconRecordType,
  AntDesignNames,
  CustomIconRecordType,
  IconName,
  IconRecordType,
  NBRecordType
} from './types.icon'
import {
  ArrowUpIcon,
  ArrowDownIcon,
  SalesTax,
  JurisdictionAssignment,
  ActivityRings,
  BulbIcon,
  BusinessPlanIcon,
  BldgCodeEnforcementIcon,
  ChevronRigthIcon,
  ClipboardIcon,
  CodeEnforcementIcon,
  CommercialPropertyIcon,
  CreditCardIcon,
  CreativeWindIcon,
  DetailIcon,
  DocumentIcon,
  DroneIcon,
  EmailIcon,
  EnergyConsCodeIcon,
  EnterprisePlanIcon,
  EnterprisePlanTwoIcon,
  ExternalLinkIcon,
  FeatureDroneIcon,
  HailTraceIcon,
  HailStormHistoryIcon,
  HeartIcon,
  HouseIcon,
  HousingDataIcon,
  IceAndWaterShieldCalculatorIcon,
  IWSIcon,
  ManufacturerRequirementsIcon,
  MessageOutlinedIcon,
  MenuIcon,
  PermitCalculatorIcon,
  PermitFeeIcon,
  PlanNationalIcon,
  PlanOneStateIcon,
  PlugIcon,
  ProjectIcon,
  PropertyDetailsIcon,
  ProPlanIcon,
  QuestionMarkOutlinedIcon,
  RatingStarIcon,
  ResidencePropertyIcon,
  SignOutIcon,
  StarterPlanIcon,
  TeamIcon,
  ToolCalculatorIcon,
  UserIcon,
  VentIcon,
  VentilationCalculatorIcon,
  WasteCalculatorIcon,
  WasteIcon,
  WindIcon,
  ArchiveIcon,
  PlusIcon,
  LocationIcon,
  LockSolidIcon,
  WeatherLockIcon,
  CodeLockIcon,
  MapIcon,
  LockIcon,
  HomeIcon,
  BuildingCodesOutlinedIcon,
  WarningTriangleOutlined,
  RadioSelect,
  RadioUnselect,
  WebOutlinedIcon,
  ContactOutlinedIcon,
  ContactPhoneOutlinedIcon,
  EmailOutlinedIcon,
  LowSlopeOutlinedIcon,
  SteepSlopeOutlinedIcon,
  EagleViewShortIcon,
  SolutionIcon,
  PropertyIbcLow,
  PropertyIbcSteep,
  PropertyIrcLow,
  PropertyIrcSteep
} from '../Icons'
import { AntDesignMap as WebAntDesignMap } from './Antd/antd'
import NativeAntdDesign from './Antd/native.antd'

const isWeb = Platform.OS === 'web'

const NativeBaseIconMap: NBRecordType = {
  moon: MoonIcon,
  sun: SunIcon,
  check: CheckIcon,
  circle: CircleIcon,
  arrowback: ArrowBackIcon,
  add: AddIcon,
  arrowforward: ArrowForwardIcon,
  checkcircle: CheckCircleIcon,
  chevrondown: ChevronDownIcon,
  chevronleft: ChevronLeftIcon,
  chevronright: ChevronRightIcon,
  chevronup: ChevronUpIcon,
  close: CloseIcon,
  hamburger: HamburgerIcon,
  info: InfoIcon,
  infooutline: InfoOutlineIcon,
  minus: MinusIcon,
  question: QuestionIcon,
  questionoutline: QuestionOutlineIcon,
  search: SearchIcon,
  warning: WarningIcon,
  warningtwo: WarningTwoIcon
}

const CustomIconMap: CustomIconRecordType = {
  arrowup: ArrowUpIcon,
  arrowdown: ArrowDownIcon,
  salestax: SalesTax,
  jurisdictionassignment: JurisdictionAssignment,
  activityrings: ActivityRings,
  bldgcodeenforcement: BldgCodeEnforcementIcon,
  hailstormhistory: HailStormHistoryIcon,
  creativewind: CreativeWindIcon,
  creditcard: CreditCardIcon,
  drone: DroneIcon,
  propertydetails: PropertyDetailsIcon,
  plannational: PlanNationalIcon,
  planonestate: PlanOneStateIcon,
  energyconscode: EnergyConsCodeIcon,
  codeenforcement: CodeEnforcementIcon,
  dronefeature: FeatureDroneIcon,
  detail: DetailIcon,
  house: HouseIcon,
  housingdata: HousingDataIcon,
  iws: IWSIcon,
  manufacturerrequirements: ManufacturerRequirementsIcon,
  permitcalculator: PermitCalculatorIcon,
  permitfee: PermitFeeIcon,
  vent: VentIcon,
  waste: WasteIcon,
  starterplan: StarterPlanIcon,
  proplan: ProPlanIcon,
  enterpriseplan: EnterprisePlanIcon,
  enterpriseplantwo: EnterprisePlanTwoIcon,
  residentialProperty: ResidencePropertyIcon,
  commercialProperty: CommercialPropertyIcon,
  businessplan: BusinessPlanIcon,
  hailtrace: HailTraceIcon,
  wind: WindIcon,
  email: EmailIcon,
  externallink: ExternalLinkIcon,
  clipboard: ClipboardIcon,
  plug: PlugIcon,
  bulb: BulbIcon,
  toolcalculator: ToolCalculatorIcon,
  project: ProjectIcon,
  menu: MenuIcon,
  heart: HeartIcon,
  document: DocumentIcon,
  questionmarkoutlined: QuestionMarkOutlinedIcon,
  ratingstar: RatingStarIcon,
  messageoutlined: MessageOutlinedIcon,
  user: UserIcon,
  signout: SignOutIcon,
  iwsnew: IceAndWaterShieldCalculatorIcon,
  ventilationnew: VentilationCalculatorIcon,
  wastenew: WasteCalculatorIcon,
  chevronrightnew: ChevronRigthIcon,
  team: TeamIcon,
  archive: ArchiveIcon,
  plus: PlusIcon,
  location: LocationIcon,
  locksolid: LockSolidIcon,
  weatherlock: WeatherLockIcon,
  codelock: CodeLockIcon,
  map: MapIcon,
  lock: LockIcon,
  home: HomeIcon,
  buildingcodesoutlined: BuildingCodesOutlinedIcon,
  warningtriangleoutlined: WarningTriangleOutlined,
  radioselect: RadioSelect,
  radiounselect: RadioUnselect,
  weboutlined: WebOutlinedIcon,
  contactoutlined: ContactOutlinedIcon,
  contactphoneoutlined: ContactPhoneOutlinedIcon,
  emailoutlined: EmailOutlinedIcon,
  lowslope: LowSlopeOutlinedIcon,
  steepslope: SteepSlopeOutlinedIcon,
  eagleviewshort: EagleViewShortIcon,
  solution: SolutionIcon,
  propertyibclow: PropertyIbcLow,
  propertyibcsteep: PropertyIbcSteep,
  propertyirclow: PropertyIrcLow,
  propertyircsteep: PropertyIrcSteep
}

export interface IIconProps extends NativeBaseIIconProps {
  name: IconName
}

export interface IIconPropsWithAntDesign extends NativeBaseIIconProps {
  name: IconName
  isAntDesign?: boolean
}

const PartialIconMaps: Partial<IconRecordType> = {
  ...NativeBaseIconMap,
  ...CustomIconMap
}

const IconMaps = ((): IconRecordType => {
  if (isWeb) {
    return { ...PartialIconMaps, ...WebAntDesignMap } as IconRecordType
  } else {
    const NativeAntMapIcons = (() => {
      let antIcons = {} as AntDesignIconRecordType

      ANT_DESIGN_ICON_NAMES.forEach((name: AntDesignNames) => {
        antIcons[name] = ({
          size,
          color,
          ...props
        }: Omit<IIconProps, 'name'>) => (
          <NativeAntdDesign
            {...props}
            color={color as ColorValue | number}
            size={size as number}
            name={name}
          />
        )
      })
      return antIcons
    })()
    return { ...PartialIconMaps, ...NativeAntMapIcons } as IconRecordType
  }
})()

const Icon = ({
  name,
  color: colorProp = 'text.50',
  isAntDesign = false,
  size,
  ...props
}: { fillColor?: string } & IIconPropsWithAntDesign) => {
  const color = useToken('colors', colorProp as string)
  const fontSize = useToken('sizes', size as string | number)

  if (IconMaps[name]) {
    const IconComponent = IconMaps[name]

    if (!isAntDesign)
      return <IconComponent {...props} size={size} color={color} />

    const iconComponentProps = isWeb ? {
      style: {
        color,
        fontSize
      }
    } : {
      size,
      style: {
        color
      }
    }

    // @ts-ignore
    return <IconComponent {...iconComponentProps} />
  }

  return (
    <NativeBaseIcon
      name="questionoutline"
      {...props}
      size={size}
      color={color}
    />
  )
}

export default Icon
