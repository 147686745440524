import React from 'react'
import { IIconButtonProps, Popover, IconButton, Tooltip } from 'native-base'
import Icon from '../Icon'

interface WebTooltipProps extends IIconButtonProps { }

export interface ITooltipPopoverProps {
  label?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  triggerProps?: WebTooltipProps
  placementMobileWeb?: 'top' | 'bottom'
  mobileWeb?: boolean
  style?: any
  size?: number
}

const WebTooltipPopover = ({
  label = '',
  placement = 'right',
  placementMobileWeb = 'bottom',
  triggerProps: IIconButtonProps = {},
  style: contentStyle = {
    content: {
      backgroundColor: '#333440',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      width: 316,
      minHeight: 33
    },
    _text: {
      color: '#ffffff',
      fontWeight: 500,
      fontSize: 14
    }
  },
  size = 5,
  mobileWeb = false,
  ...props
}: ITooltipPopoverProps) => {
  if (mobileWeb) {
    return (
      <Popover
        {...props}
        placement={placementMobileWeb}
        trapFocus
        trigger={triggerProps => (
          <IconButton
            p={0}
            ml="2"
            mt="1.5px"
            mb="1.5px"
            mr="1.5px"
            icon={
              <Icon
                name="infocirclefilled"
                size={size}
                color="cyan.200"
                isAntDesign={true}
              />
            }
            {...triggerProps}
          />
        )}>
        <Popover.Content borderWidth={0}>
          <Popover.Body style={contentStyle.content} _text={contentStyle._text}>
            {label}
          </Popover.Body>
        </Popover.Content>
      </Popover>
    )
  }
  return (
    <Tooltip
      placement={placement}
      style={contentStyle.content}
      _text={contentStyle._text}
      label={label}>
      <IconButton
        p={0}
        ml="2"
        mt="1.5px"
        mb="1.5px"
        mr="1.5px"
        focusable={false}
        icon={
          <Icon
            name="infocirclefilled"
            size={size}
            color="cyan.200"
            isAntDesign={true}
          />
        }
      />
    </Tooltip>
  )
}

export default WebTooltipPopover
