import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import Icon from '../../Icon'

const FreeTrialBanner = ({
  isMobile = false,
  onExplorePlans,
  containerStyle,
  messageContainerStyle,
  titleStyle,
  descriptionStyle,
  buttonContainerStyle,
  buttonStyle,
  iconColor = '#FCB627',
  iconWidth,
  iconHeight
}) => {
  const explorePlansClick = () => {
    onExplorePlans && onExplorePlans()
  }

  return (
    <View
      style={[
        styles.container,
        isMobile && mobileStyles.container,
        containerStyle
      ]}>
      <View
        style={[
          styles.messageContainer,
          isMobile && mobileStyles.messageContainer,
          messageContainerStyle
        ]}>
        <Text
          style={[styles.title, isMobile && mobileStyles.title, titleStyle]}>
          {`Your `}
          <Text
            style={[
              styles.title,
              isMobile && mobileStyles.title,
              styles.blueText,
              titleStyle
            ]}>
            FREE Trial
          </Text>
          {` is over!`}
        </Text>

        <Text
          style={[
            styles.description,
            isMobile && mobileStyles.description,
            descriptionStyle
          ]}>
          You'll keep projects created during your trial however you must select
          a plan to create additional projects.
        </Text>
      </View>
      <TouchableOpacity
        style={[
          styles.buttonContainer,
          isMobile && mobileStyles.buttonContainer,
          buttonContainerStyle
        ]}
        onPress={explorePlansClick}>
        <Text
          style={[styles.button, isMobile && mobileStyles.button, buttonStyle]}>
          Explore Plans
        </Text>

        {isMobile && <Icon name="arrowforward" size="xs" color={iconColor} />}
      </TouchableOpacity>
    </View>
  )
}

export default FreeTrialBanner

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 18,
    paddingHorizontal: 24,
    width: '100%',
    minHeight: 111,
    alignItems: 'center',
    gap: 24,
    backgroundColor: '#191A27',
    borderWidth: 1,
    borderColor: '#333440',
    borderStyle: 'solid',
    borderRadius: 16,
    marginBottom: 32
  },
  messageContainer: {
    flexDirection: 'column',
    width: '50%'
  },
  title: {
    fontFamily: 'Barlow',
    fontSize: 24,
    fontWeight: '700',
    lineHeight: 29,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#ffffff'
  },
  blueText: {
    color: '#4694F0'
  },
  description: {
    fontFamily: 'Barlow',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#ffffff',
    marginTop: 8
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    maxWidth: 276,
    height: 48,
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    paddingHorizontal: 24,
    gap: 8,
    borderWidth: 1,
    borderColor: '#FEAC01',
    borderStyle: 'solid',
    borderRadius: 4,
    order: 0,
    flexGrow: 0,
    textAlign: 'center'
  },
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontFamily: 'Barlow',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 19,
    letterSpacing: 0
  }
})

const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: null,
    height: null,
    gap: 8,
    alignSelf: 'stretch',
    marginHorizontal: 16,
    marginBottom: 0
  },
  messageContainer: {
    width: '100%'
  },
  buttonContainer: {
    borderWidth: 0,
    borderRadius: 0,
    paddingHorizontal: 0,
    height: 20,
    width: null,
    maxWidth: 150,
    textAlign: 'right',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    marginTop: 8,
    gap: 0
  },
  button: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 14,
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: '#FCB627',
    textAlign: 'right',
    marginRight: 10
  },
  title: {
    fontSize: 18,
    lineHeight: 22,
    color: '#ffffff'
  },
  description: {
    fontSize: 12,
    lineHeight: 14,
    textAlign: 'justify'
  }
})
