import React from 'react'
import { CheckIcon, CloseIcon, IIconProps, View, Text } from 'native-base'
import Icon from '../../Icon'
import { StatusType, STATUS } from '../types'

export interface IStatusIconProps extends IIconProps {
  status: StatusType
}

const Helper = {
  [STATUS.success]: (props: IIconProps) => <CheckIcon {...props} />,
  [STATUS.error]: (props: IIconProps) => <CloseIcon {...props} />,
  [STATUS.info]: ({ color, size }: IIconProps) => (
    <Text
      p="0"
      m="0"
      fontSize="sm"
      color={color}
      _ios={{ mb: 0, pt: 1 }}
      _android={{ mb: 0, pt: 1 }}
      _web={{ verticalAlign: 'center' }}
      textAlign="center"
    >
      <Icon name="warningfilled" color={color} size={size} />
    </Text>
  ),
  [STATUS.addressInfoNotFoundNative]: ({ color, size }: IIconProps) => (
    <Text
      p="0"
      m="0"
      fontSize="sm"
      color={color}
      _ios={{ mb: 0, pt: 1 }}
      _android={{ mb: 0, pt: 1 }}
      textAlign="center"
    >
      <Icon name="warningfilled" color={color} size={size} />
    </Text>
  )
}

const StatusIcon = ({
  status,
  size,
  w,
  h,
  borderRadius,
  bg,
  ...props
}: IStatusIconProps) => {
  const Component = Helper[status] || Helper.info
  return (
    <View
      borderRadius={borderRadius}
      bg={bg}
      w={w}
      h={h}
      justifyContent="center"
      alignItems="center">
      <Component
        {...props}
        size={size}
        w={w}
        h={h}
        bg={bg}
        borderRadius={borderRadius}
        borderWidth={0}
      />
    </View>
  )
}

export default StatusIcon
