import React from 'react'

import { AntDesignNames } from './types.antd'

let AntDesignIcons: any
try {
  AntDesignIcons = require('@ant-design/icons')
  // do stuff
} catch (ex) {
  AntDesignIcons = {}
}

export const AntDesignMap: Record<AntDesignNames, any> = {
  accountbookfilled: AntDesignIcons.AccountBookFilled,
  accountbookoutlined: AntDesignIcons.AccountBookOutlined,
  accountbooktwotone: AntDesignIcons.AccountBookTwoTone,
  aimoutlined: AntDesignIcons.AimOutlined,
  alertfilled: AntDesignIcons.AlertFilled,
  alertoutlined: AntDesignIcons.AlertOutlined,
  alerttwotone: AntDesignIcons.AlertTwoTone,
  alibabaoutlined: AntDesignIcons.AlibabaOutlined,
  aligncenteroutlined: AntDesignIcons.AlignCenterOutlined,
  alignleftoutlined: AntDesignIcons.AlignLeftOutlined,
  alignrightoutlined: AntDesignIcons.AlignRightOutlined,
  alipaycirclefilled: AntDesignIcons.AlipayCircleFilled,
  alipaycircleoutlined: AntDesignIcons.AlipayCircleOutlined,
  alipayoutlined: AntDesignIcons.AlipayOutlined,
  alipaysquarefilled: AntDesignIcons.AlipaySquareFilled,
  aliwangwangfilled: AntDesignIcons.AliwangwangFilled,
  aliwangwangoutlined: AntDesignIcons.AliwangwangOutlined,
  aliyunoutlined: AntDesignIcons.AliyunOutlined,
  amazoncirclefilled: AntDesignIcons.AmazonCircleFilled,
  amazonoutlined: AntDesignIcons.AmazonOutlined,
  amazonsquarefilled: AntDesignIcons.AmazonSquareFilled,
  androidfilled: AntDesignIcons.AndroidFilled,
  androidoutlined: AntDesignIcons.AndroidOutlined,
  antcloudoutlined: AntDesignIcons.AntCloudOutlined,
  antdesignoutlined: AntDesignIcons.AntDesignOutlined,
  apartmentoutlined: AntDesignIcons.ApartmentOutlined,
  apifilled: AntDesignIcons.ApiFilled,
  apioutlined: AntDesignIcons.ApiOutlined,
  apitwotone: AntDesignIcons.ApiTwoTone,
  applefilled: AntDesignIcons.AppleFilled,
  appleoutlined: AntDesignIcons.AppleOutlined,
  appstoreaddoutlined: AntDesignIcons.AppstoreAddOutlined,
  appstorefilled: AntDesignIcons.AppstoreFilled,
  appstoreoutlined: AntDesignIcons.AppstoreOutlined,
  appstoretwotone: AntDesignIcons.AppstoreTwoTone,
  areachartoutlined: AntDesignIcons.AreaChartOutlined,
  arrowdownoutlined: AntDesignIcons.ArrowDownOutlined,
  arrowleftoutlined: AntDesignIcons.ArrowLeftOutlined,
  arrowrightoutlined: AntDesignIcons.ArrowRightOutlined,
  arrowupoutlined: AntDesignIcons.ArrowUpOutlined,
  arrowsaltoutlined: AntDesignIcons.ArrowsAltOutlined,
  audiofilled: AntDesignIcons.AudioFilled,
  audiomutedoutlined: AntDesignIcons.AudioMutedOutlined,
  audiooutlined: AntDesignIcons.AudioOutlined,
  audiotwotone: AntDesignIcons.AudioTwoTone,
  auditoutlined: AntDesignIcons.AuditOutlined,
  backwardfilled: AntDesignIcons.BackwardFilled,
  backwardoutlined: AntDesignIcons.BackwardOutlined,
  bankfilled: AntDesignIcons.BankFilled,
  bankoutlined: AntDesignIcons.BankOutlined,
  banktwotone: AntDesignIcons.BankTwoTone,
  barchartoutlined: AntDesignIcons.BarChartOutlined,
  barcodeoutlined: AntDesignIcons.BarcodeOutlined,
  barsoutlined: AntDesignIcons.BarsOutlined,
  behancecirclefilled: AntDesignIcons.BehanceCircleFilled,
  behanceoutlined: AntDesignIcons.BehanceOutlined,
  behancesquarefilled: AntDesignIcons.BehanceSquareFilled,
  behancesquareoutlined: AntDesignIcons.BehanceSquareOutlined,
  bellfilled: AntDesignIcons.BellFilled,
  belloutlined: AntDesignIcons.BellOutlined,
  belltwotone: AntDesignIcons.BellTwoTone,
  bgcolorsoutlined: AntDesignIcons.BgColorsOutlined,
  blockoutlined: AntDesignIcons.BlockOutlined,
  boldoutlined: AntDesignIcons.BoldOutlined,
  bookfilled: AntDesignIcons.BookFilled,
  bookoutlined: AntDesignIcons.BookOutlined,
  booktwotone: AntDesignIcons.BookTwoTone,
  borderbottomoutlined: AntDesignIcons.BorderBottomOutlined,
  borderhorizontaloutlined: AntDesignIcons.BorderHorizontalOutlined,
  borderinneroutlined: AntDesignIcons.BorderInnerOutlined,
  borderleftoutlined: AntDesignIcons.BorderLeftOutlined,
  borderouteroutlined: AntDesignIcons.BorderOuterOutlined,
  borderoutlined: AntDesignIcons.BorderOutlined,
  borderrightoutlined: AntDesignIcons.BorderRightOutlined,
  bordertopoutlined: AntDesignIcons.BorderTopOutlined,
  borderverticleoutlined: AntDesignIcons.BorderVerticleOutlined,
  borderlesstableoutlined: AntDesignIcons.BorderlessTableOutlined,
  boxplotfilled: AntDesignIcons.BoxPlotFilled,
  boxplotoutlined: AntDesignIcons.BoxPlotOutlined,
  boxplottwotone: AntDesignIcons.BoxPlotTwoTone,
  branchesoutlined: AntDesignIcons.BranchesOutlined,
  bugfilled: AntDesignIcons.BugFilled,
  bugoutlined: AntDesignIcons.BugOutlined,
  bugtwotone: AntDesignIcons.BugTwoTone,
  buildfilled: AntDesignIcons.BuildFilled,
  buildoutlined: AntDesignIcons.BuildOutlined,
  buildtwotone: AntDesignIcons.BuildTwoTone,
  bulbfilled: AntDesignIcons.BulbFilled,
  bulboutlined: AntDesignIcons.BulbOutlined,
  bulbtwotone: AntDesignIcons.BulbTwoTone,
  calculatorfilled: AntDesignIcons.CalculatorFilled,
  calculatoroutlined: AntDesignIcons.CalculatorOutlined,
  calculatortwotone: AntDesignIcons.CalculatorTwoTone,
  calendarfilled: AntDesignIcons.CalendarFilled,
  calendaroutlined: AntDesignIcons.CalendarOutlined,
  calendartwotone: AntDesignIcons.CalendarTwoTone,
  camerafilled: AntDesignIcons.CameraFilled,
  cameraoutlined: AntDesignIcons.CameraOutlined,
  cameratwotone: AntDesignIcons.CameraTwoTone,
  carfilled: AntDesignIcons.CarFilled,
  caroutlined: AntDesignIcons.CarOutlined,
  cartwotone: AntDesignIcons.CarTwoTone,
  caretdownfilled: AntDesignIcons.CaretDownFilled,
  caretdownoutlined: AntDesignIcons.CaretDownOutlined,
  caretleftfilled: AntDesignIcons.CaretLeftFilled,
  caretleftoutlined: AntDesignIcons.CaretLeftOutlined,
  caretrightfilled: AntDesignIcons.CaretRightFilled,
  caretrightoutlined: AntDesignIcons.CaretRightOutlined,
  caretupfilled: AntDesignIcons.CaretUpFilled,
  caretupoutlined: AntDesignIcons.CaretUpOutlined,
  carryoutfilled: AntDesignIcons.CarryOutFilled,
  carryoutoutlined: AntDesignIcons.CarryOutOutlined,
  carryouttwotone: AntDesignIcons.CarryOutTwoTone,
  checkcirclefilled: AntDesignIcons.CheckCircleFilled,
  checkcircleoutlined: AntDesignIcons.CheckCircleOutlined,
  checkcircletwotone: AntDesignIcons.CheckCircleTwoTone,
  checkoutlined: AntDesignIcons.CheckOutlined,
  checksquarefilled: AntDesignIcons.CheckSquareFilled,
  checksquareoutlined: AntDesignIcons.CheckSquareOutlined,
  checksquaretwotone: AntDesignIcons.CheckSquareTwoTone,
  chromefilled: AntDesignIcons.ChromeFilled,
  chromeoutlined: AntDesignIcons.ChromeOutlined,
  cicirclefilled: AntDesignIcons.CiCircleFilled,
  cicircleoutlined: AntDesignIcons.CiCircleOutlined,
  cicircletwotone: AntDesignIcons.CiCircleTwoTone,
  cioutlined: AntDesignIcons.CiOutlined,
  citwotone: AntDesignIcons.CiTwoTone,
  clearoutlined: AntDesignIcons.ClearOutlined,
  clockcirclefilled: AntDesignIcons.ClockCircleFilled,
  clockcircleoutlined: AntDesignIcons.ClockCircleOutlined,
  clockcircletwotone: AntDesignIcons.ClockCircleTwoTone,
  closecirclefilled: AntDesignIcons.CloseCircleFilled,
  closecircleoutlined: AntDesignIcons.CloseCircleOutlined,
  closecircletwotone: AntDesignIcons.CloseCircleTwoTone,
  closeoutlined: AntDesignIcons.CloseOutlined,
  closesquarefilled: AntDesignIcons.CloseSquareFilled,
  closesquareoutlined: AntDesignIcons.CloseSquareOutlined,
  closesquaretwotone: AntDesignIcons.CloseSquareTwoTone,
  clouddownloadoutlined: AntDesignIcons.CloudDownloadOutlined,
  cloudfilled: AntDesignIcons.CloudFilled,
  cloudoutlined: AntDesignIcons.CloudOutlined,
  cloudserveroutlined: AntDesignIcons.CloudServerOutlined,
  cloudsyncoutlined: AntDesignIcons.CloudSyncOutlined,
  cloudtwotone: AntDesignIcons.CloudTwoTone,
  clouduploadoutlined: AntDesignIcons.CloudUploadOutlined,
  clusteroutlined: AntDesignIcons.ClusterOutlined,
  codefilled: AntDesignIcons.CodeFilled,
  codeoutlined: AntDesignIcons.CodeOutlined,
  codesandboxcirclefilled: AntDesignIcons.CodeSandboxCircleFilled,
  codesandboxoutlined: AntDesignIcons.CodeSandboxOutlined,
  codesandboxsquarefilled: AntDesignIcons.CodeSandboxSquareFilled,
  codetwotone: AntDesignIcons.CodeTwoTone,
  codepencirclefilled: AntDesignIcons.CodepenCircleFilled,
  codepencircleoutlined: AntDesignIcons.CodepenCircleOutlined,
  codepenoutlined: AntDesignIcons.CodepenOutlined,
  codepensquarefilled: AntDesignIcons.CodepenSquareFilled,
  coffeeoutlined: AntDesignIcons.CoffeeOutlined,
  columnheightoutlined: AntDesignIcons.ColumnHeightOutlined,
  columnwidthoutlined: AntDesignIcons.ColumnWidthOutlined,
  commentoutlined: AntDesignIcons.CommentOutlined,
  compassfilled: AntDesignIcons.CompassFilled,
  compassoutlined: AntDesignIcons.CompassOutlined,
  compasstwotone: AntDesignIcons.CompassTwoTone,
  compressoutlined: AntDesignIcons.CompressOutlined,
  consolesqloutlined: AntDesignIcons.ConsoleSqlOutlined,
  contactsfilled: AntDesignIcons.ContactsFilled,
  contactsoutlined: AntDesignIcons.ContactsOutlined,
  contactstwotone: AntDesignIcons.ContactsTwoTone,
  containerfilled: AntDesignIcons.ContainerFilled,
  containeroutlined: AntDesignIcons.ContainerOutlined,
  containertwotone: AntDesignIcons.ContainerTwoTone,
  controlfilled: AntDesignIcons.ControlFilled,
  controloutlined: AntDesignIcons.ControlOutlined,
  controltwotone: AntDesignIcons.ControlTwoTone,
  copyfilled: AntDesignIcons.CopyFilled,
  copyoutlined: AntDesignIcons.CopyOutlined,
  copytwotone: AntDesignIcons.CopyTwoTone,
  copyrightcirclefilled: AntDesignIcons.CopyrightCircleFilled,
  copyrightcircleoutlined: AntDesignIcons.CopyrightCircleOutlined,
  copyrightcircletwotone: AntDesignIcons.CopyrightCircleTwoTone,
  copyrightoutlined: AntDesignIcons.CopyrightOutlined,
  copyrighttwotone: AntDesignIcons.CopyrightTwoTone,
  creditcardfilled: AntDesignIcons.CreditCardFilled,
  creditcardoutlined: AntDesignIcons.CreditCardOutlined,
  creditcardtwotone: AntDesignIcons.CreditCardTwoTone,
  crownfilled: AntDesignIcons.CrownFilled,
  crownoutlined: AntDesignIcons.CrownOutlined,
  crowntwotone: AntDesignIcons.CrownTwoTone,
  customerservicefilled: AntDesignIcons.CustomerServiceFilled,
  customerserviceoutlined: AntDesignIcons.CustomerServiceOutlined,
  customerservicetwotone: AntDesignIcons.CustomerServiceTwoTone,
  dashoutlined: AntDesignIcons.DashOutlined,
  dashboardfilled: AntDesignIcons.DashboardFilled,
  dashboardoutlined: AntDesignIcons.DashboardOutlined,
  dashboardtwotone: AntDesignIcons.DashboardTwoTone,
  databasefilled: AntDesignIcons.DatabaseFilled,
  databaseoutlined: AntDesignIcons.DatabaseOutlined,
  databasetwotone: AntDesignIcons.DatabaseTwoTone,
  deletecolumnoutlined: AntDesignIcons.DeleteColumnOutlined,
  deletefilled: AntDesignIcons.DeleteFilled,
  deleteoutlined: AntDesignIcons.DeleteOutlined,
  deleterowoutlined: AntDesignIcons.DeleteRowOutlined,
  deletetwotone: AntDesignIcons.DeleteTwoTone,
  deliveredprocedureoutlined: AntDesignIcons.DeliveredProcedureOutlined,
  deploymentunitoutlined: AntDesignIcons.DeploymentUnitOutlined,
  desktopoutlined: AntDesignIcons.DesktopOutlined,
  difffilled: AntDesignIcons.DiffFilled,
  diffoutlined: AntDesignIcons.DiffOutlined,
  difftwotone: AntDesignIcons.DiffTwoTone,
  dingdingoutlined: AntDesignIcons.DingdingOutlined,
  dingtalkcirclefilled: AntDesignIcons.DingtalkCircleFilled,
  dingtalkoutlined: AntDesignIcons.DingtalkOutlined,
  dingtalksquarefilled: AntDesignIcons.DingtalkSquareFilled,
  disconnectoutlined: AntDesignIcons.DisconnectOutlined,
  dislikefilled: AntDesignIcons.DislikeFilled,
  dislikeoutlined: AntDesignIcons.DislikeOutlined,
  disliketwotone: AntDesignIcons.DislikeTwoTone,
  dollarcirclefilled: AntDesignIcons.DollarCircleFilled,
  dollarcircleoutlined: AntDesignIcons.DollarCircleOutlined,
  dollarcircletwotone: AntDesignIcons.DollarCircleTwoTone,
  dollaroutlined: AntDesignIcons.DollarOutlined,
  dollartwotone: AntDesignIcons.DollarTwoTone,
  dotchartoutlined: AntDesignIcons.DotChartOutlined,
  doubleleftoutlined: AntDesignIcons.DoubleLeftOutlined,
  doublerightoutlined: AntDesignIcons.DoubleRightOutlined,
  downcirclefilled: AntDesignIcons.DownCircleFilled,
  downcircleoutlined: AntDesignIcons.DownCircleOutlined,
  downcircletwotone: AntDesignIcons.DownCircleTwoTone,
  downoutlined: AntDesignIcons.DownOutlined,
  downsquarefilled: AntDesignIcons.DownSquareFilled,
  downsquareoutlined: AntDesignIcons.DownSquareOutlined,
  downsquaretwotone: AntDesignIcons.DownSquareTwoTone,
  downloadoutlined: AntDesignIcons.DownloadOutlined,
  dragoutlined: AntDesignIcons.DragOutlined,
  dribbblecirclefilled: AntDesignIcons.DribbbleCircleFilled,
  dribbbleoutlined: AntDesignIcons.DribbbleOutlined,
  dribbblesquarefilled: AntDesignIcons.DribbbleSquareFilled,
  dribbblesquareoutlined: AntDesignIcons.DribbbleSquareOutlined,
  dropboxcirclefilled: AntDesignIcons.DropboxCircleFilled,
  dropboxoutlined: AntDesignIcons.DropboxOutlined,
  dropboxsquarefilled: AntDesignIcons.DropboxSquareFilled,
  editfilled: AntDesignIcons.EditFilled,
  editoutlined: AntDesignIcons.EditOutlined,
  edittwotone: AntDesignIcons.EditTwoTone,
  ellipsisoutlined: AntDesignIcons.EllipsisOutlined,
  enteroutlined: AntDesignIcons.EnterOutlined,
  environmentfilled: AntDesignIcons.EnvironmentFilled,
  environmentoutlined: AntDesignIcons.EnvironmentOutlined,
  environmenttwotone: AntDesignIcons.EnvironmentTwoTone,
  eurocirclefilled: AntDesignIcons.EuroCircleFilled,
  eurocircleoutlined: AntDesignIcons.EuroCircleOutlined,
  eurocircletwotone: AntDesignIcons.EuroCircleTwoTone,
  eurooutlined: AntDesignIcons.EuroOutlined,
  eurotwotone: AntDesignIcons.EuroTwoTone,
  exceptionoutlined: AntDesignIcons.ExceptionOutlined,
  exclamationcirclefilled: AntDesignIcons.ExclamationCircleFilled,
  exclamationcircleoutlined: AntDesignIcons.ExclamationCircleOutlined,
  exclamationcircletwotone: AntDesignIcons.ExclamationCircleTwoTone,
  exclamationoutlined: AntDesignIcons.ExclamationOutlined,
  expandaltoutlined: AntDesignIcons.ExpandAltOutlined,
  expandoutlined: AntDesignIcons.ExpandOutlined,
  experimentfilled: AntDesignIcons.ExperimentFilled,
  experimentoutlined: AntDesignIcons.ExperimentOutlined,
  experimenttwotone: AntDesignIcons.ExperimentTwoTone,
  exportoutlined: AntDesignIcons.ExportOutlined,
  eyefilled: AntDesignIcons.EyeFilled,
  eyeinvisiblefilled: AntDesignIcons.EyeInvisibleFilled,
  eyeinvisibleoutlined: AntDesignIcons.EyeInvisibleOutlined,
  eyeinvisibletwotone: AntDesignIcons.EyeInvisibleTwoTone,
  eyeoutlined: AntDesignIcons.EyeOutlined,
  eyetwotone: AntDesignIcons.EyeTwoTone,
  facebookfilled: AntDesignIcons.FacebookFilled,
  facebookoutlined: AntDesignIcons.FacebookOutlined,
  falloutlined: AntDesignIcons.FallOutlined,
  fastbackwardfilled: AntDesignIcons.FastBackwardFilled,
  fastbackwardoutlined: AntDesignIcons.FastBackwardOutlined,
  fastforwardfilled: AntDesignIcons.FastForwardFilled,
  fastforwardoutlined: AntDesignIcons.FastForwardOutlined,
  fieldbinaryoutlined: AntDesignIcons.FieldBinaryOutlined,
  fieldnumberoutlined: AntDesignIcons.FieldNumberOutlined,
  fieldstringoutlined: AntDesignIcons.FieldStringOutlined,
  fieldtimeoutlined: AntDesignIcons.FieldTimeOutlined,
  fileaddfilled: AntDesignIcons.FileAddFilled,
  fileaddoutlined: AntDesignIcons.FileAddOutlined,
  fileaddtwotone: AntDesignIcons.FileAddTwoTone,
  filedoneoutlined: AntDesignIcons.FileDoneOutlined,
  fileexcelfilled: AntDesignIcons.FileExcelFilled,
  fileexceloutlined: AntDesignIcons.FileExcelOutlined,
  fileexceltwotone: AntDesignIcons.FileExcelTwoTone,
  fileexclamationfilled: AntDesignIcons.FileExclamationFilled,
  fileexclamationoutlined: AntDesignIcons.FileExclamationOutlined,
  fileexclamationtwotone: AntDesignIcons.FileExclamationTwoTone,
  filefilled: AntDesignIcons.FileFilled,
  filegifoutlined: AntDesignIcons.FileGifOutlined,
  fileimagefilled: AntDesignIcons.FileImageFilled,
  fileimageoutlined: AntDesignIcons.FileImageOutlined,
  fileimagetwotone: AntDesignIcons.FileImageTwoTone,
  filejpgoutlined: AntDesignIcons.FileJpgOutlined,
  filemarkdownfilled: AntDesignIcons.FileMarkdownFilled,
  filemarkdownoutlined: AntDesignIcons.FileMarkdownOutlined,
  filemarkdowntwotone: AntDesignIcons.FileMarkdownTwoTone,
  fileoutlined: AntDesignIcons.FileOutlined,
  filepdffilled: AntDesignIcons.FilePdfFilled,
  filepdfoutlined: AntDesignIcons.FilePdfOutlined,
  filepdftwotone: AntDesignIcons.FilePdfTwoTone,
  filepptfilled: AntDesignIcons.FilePptFilled,
  filepptoutlined: AntDesignIcons.FilePptOutlined,
  fileppttwotone: AntDesignIcons.FilePptTwoTone,
  fileprotectoutlined: AntDesignIcons.FileProtectOutlined,
  filesearchoutlined: AntDesignIcons.FileSearchOutlined,
  filesyncoutlined: AntDesignIcons.FileSyncOutlined,
  filetextfilled: AntDesignIcons.FileTextFilled,
  filetextoutlined: AntDesignIcons.FileTextOutlined,
  filetexttwotone: AntDesignIcons.FileTextTwoTone,
  filetwotone: AntDesignIcons.FileTwoTone,
  fileunknownfilled: AntDesignIcons.FileUnknownFilled,
  fileunknownoutlined: AntDesignIcons.FileUnknownOutlined,
  fileunknowntwotone: AntDesignIcons.FileUnknownTwoTone,
  filewordfilled: AntDesignIcons.FileWordFilled,
  filewordoutlined: AntDesignIcons.FileWordOutlined,
  filewordtwotone: AntDesignIcons.FileWordTwoTone,
  filezipfilled: AntDesignIcons.FileZipFilled,
  filezipoutlined: AntDesignIcons.FileZipOutlined,
  fileziptwotone: AntDesignIcons.FileZipTwoTone,
  filterfilled: AntDesignIcons.FilterFilled,
  filteroutlined: AntDesignIcons.FilterOutlined,
  filtertwotone: AntDesignIcons.FilterTwoTone,
  firefilled: AntDesignIcons.FireFilled,
  fireoutlined: AntDesignIcons.FireOutlined,
  firetwotone: AntDesignIcons.FireTwoTone,
  flagfilled: AntDesignIcons.FlagFilled,
  flagoutlined: AntDesignIcons.FlagOutlined,
  flagtwotone: AntDesignIcons.FlagTwoTone,
  folderaddfilled: AntDesignIcons.FolderAddFilled,
  folderaddoutlined: AntDesignIcons.FolderAddOutlined,
  folderaddtwotone: AntDesignIcons.FolderAddTwoTone,
  folderfilled: AntDesignIcons.FolderFilled,
  folderopenfilled: AntDesignIcons.FolderOpenFilled,
  folderopenoutlined: AntDesignIcons.FolderOpenOutlined,
  folderopentwotone: AntDesignIcons.FolderOpenTwoTone,
  folderoutlined: AntDesignIcons.FolderOutlined,
  foldertwotone: AntDesignIcons.FolderTwoTone,
  folderviewoutlined: AntDesignIcons.FolderViewOutlined,
  fontcolorsoutlined: AntDesignIcons.FontColorsOutlined,
  fontsizeoutlined: AntDesignIcons.FontSizeOutlined,
  forkoutlined: AntDesignIcons.ForkOutlined,
  formoutlined: AntDesignIcons.FormOutlined,
  formatpainterfilled: AntDesignIcons.FormatPainterFilled,
  formatpainteroutlined: AntDesignIcons.FormatPainterOutlined,
  forwardfilled: AntDesignIcons.ForwardFilled,
  forwardoutlined: AntDesignIcons.ForwardOutlined,
  frownfilled: AntDesignIcons.FrownFilled,
  frownoutlined: AntDesignIcons.FrownOutlined,
  frowntwotone: AntDesignIcons.FrownTwoTone,
  fullscreenexitoutlined: AntDesignIcons.FullscreenExitOutlined,
  fullscreenoutlined: AntDesignIcons.FullscreenOutlined,
  functionoutlined: AntDesignIcons.FunctionOutlined,
  fundfilled: AntDesignIcons.FundFilled,
  fundoutlined: AntDesignIcons.FundOutlined,
  fundprojectionscreenoutlined: AntDesignIcons.FundProjectionScreenOutlined,
  fundtwotone: AntDesignIcons.FundTwoTone,
  fundviewoutlined: AntDesignIcons.FundViewOutlined,
  funnelplotfilled: AntDesignIcons.FunnelPlotFilled,
  funnelplotoutlined: AntDesignIcons.FunnelPlotOutlined,
  funnelplottwotone: AntDesignIcons.FunnelPlotTwoTone,
  gatewayoutlined: AntDesignIcons.GatewayOutlined,
  gifoutlined: AntDesignIcons.GifOutlined,
  giftfilled: AntDesignIcons.GiftFilled,
  giftoutlined: AntDesignIcons.GiftOutlined,
  gifttwotone: AntDesignIcons.GiftTwoTone,
  githubfilled: AntDesignIcons.GithubFilled,
  githuboutlined: AntDesignIcons.GithubOutlined,
  gitlabfilled: AntDesignIcons.GitlabFilled,
  gitlaboutlined: AntDesignIcons.GitlabOutlined,
  globaloutlined: AntDesignIcons.GlobalOutlined,
  goldfilled: AntDesignIcons.GoldFilled,
  goldoutlined: AntDesignIcons.GoldOutlined,
  goldtwotone: AntDesignIcons.GoldTwoTone,
  goldenfilled: AntDesignIcons.GoldenFilled,
  googlecirclefilled: AntDesignIcons.GoogleCircleFilled,
  googleoutlined: AntDesignIcons.GoogleOutlined,
  googlepluscirclefilled: AntDesignIcons.GooglePlusCircleFilled,
  googleplusoutlined: AntDesignIcons.GooglePlusOutlined,
  googleplussquarefilled: AntDesignIcons.GooglePlusSquareFilled,
  googlesquarefilled: AntDesignIcons.GoogleSquareFilled,
  groupoutlined: AntDesignIcons.GroupOutlined,
  hddfilled: AntDesignIcons.HddFilled,
  hddoutlined: AntDesignIcons.HddOutlined,
  hddtwotone: AntDesignIcons.HddTwoTone,
  heartfilled: AntDesignIcons.HeartFilled,
  heartoutlined: AntDesignIcons.HeartOutlined,
  hearttwotone: AntDesignIcons.HeartTwoTone,
  heatmapoutlined: AntDesignIcons.HeatMapOutlined,
  highlightfilled: AntDesignIcons.HighlightFilled,
  highlightoutlined: AntDesignIcons.HighlightOutlined,
  highlighttwotone: AntDesignIcons.HighlightTwoTone,
  historyoutlined: AntDesignIcons.HistoryOutlined,
  holderoutlined: AntDesignIcons.HolderOutlined,
  homefilled: AntDesignIcons.HomeFilled,
  homeoutlined: AntDesignIcons.HomeOutlined,
  hometwotone: AntDesignIcons.HomeTwoTone,
  hourglassfilled: AntDesignIcons.HourglassFilled,
  hourglassoutlined: AntDesignIcons.HourglassOutlined,
  hourglasstwotone: AntDesignIcons.HourglassTwoTone,
  html5filled: AntDesignIcons.Html5Filled,
  html5outlined: AntDesignIcons.Html5Outlined,
  html5twotone: AntDesignIcons.Html5TwoTone,
  idcardfilled: AntDesignIcons.IdcardFilled,
  idcardoutlined: AntDesignIcons.IdcardOutlined,
  idcardtwotone: AntDesignIcons.IdcardTwoTone,
  iecirclefilled: AntDesignIcons.IeCircleFilled,
  ieoutlined: AntDesignIcons.IeOutlined,
  iesquarefilled: AntDesignIcons.IeSquareFilled,
  importoutlined: AntDesignIcons.ImportOutlined,
  inboxoutlined: AntDesignIcons.InboxOutlined,
  infocirclefilled: AntDesignIcons.InfoCircleFilled,
  infocircleoutlined: AntDesignIcons.InfoCircleOutlined,
  infocircletwotone: AntDesignIcons.InfoCircleTwoTone,
  infooutlined: AntDesignIcons.InfoOutlined,
  insertrowaboveoutlined: AntDesignIcons.InsertRowAboveOutlined,
  insertrowbelowoutlined: AntDesignIcons.InsertRowBelowOutlined,
  insertrowleftoutlined: AntDesignIcons.InsertRowLeftOutlined,
  insertrowrightoutlined: AntDesignIcons.InsertRowRightOutlined,
  instagramfilled: AntDesignIcons.InstagramFilled,
  instagramoutlined: AntDesignIcons.InstagramOutlined,
  insurancefilled: AntDesignIcons.InsuranceFilled,
  insuranceoutlined: AntDesignIcons.InsuranceOutlined,
  insurancetwotone: AntDesignIcons.InsuranceTwoTone,
  interactionfilled: AntDesignIcons.InteractionFilled,
  interactionoutlined: AntDesignIcons.InteractionOutlined,
  interactiontwotone: AntDesignIcons.InteractionTwoTone,
  issuescloseoutlined: AntDesignIcons.IssuesCloseOutlined,
  italicoutlined: AntDesignIcons.ItalicOutlined,
  keyoutlined: AntDesignIcons.KeyOutlined,
  laptopoutlined: AntDesignIcons.LaptopOutlined,
  layoutfilled: AntDesignIcons.LayoutFilled,
  layoutoutlined: AntDesignIcons.LayoutOutlined,
  layouttwotone: AntDesignIcons.LayoutTwoTone,
  leftcirclefilled: AntDesignIcons.LeftCircleFilled,
  leftcircleoutlined: AntDesignIcons.LeftCircleOutlined,
  leftcircletwotone: AntDesignIcons.LeftCircleTwoTone,
  leftoutlined: AntDesignIcons.LeftOutlined,
  leftsquarefilled: AntDesignIcons.LeftSquareFilled,
  leftsquareoutlined: AntDesignIcons.LeftSquareOutlined,
  leftsquaretwotone: AntDesignIcons.LeftSquareTwoTone,
  likefilled: AntDesignIcons.LikeFilled,
  likeoutlined: AntDesignIcons.LikeOutlined,
  liketwotone: AntDesignIcons.LikeTwoTone,
  linechartoutlined: AntDesignIcons.LineChartOutlined,
  lineheightoutlined: AntDesignIcons.LineHeightOutlined,
  lineoutlined: AntDesignIcons.LineOutlined,
  linkoutlined: AntDesignIcons.LinkOutlined,
  linkedinfilled: AntDesignIcons.LinkedinFilled,
  linkedinoutlined: AntDesignIcons.LinkedinOutlined,
  loading3quartersoutlined: AntDesignIcons.Loading3QuartersOutlined,
  loadingoutlined: AntDesignIcons.LoadingOutlined,
  lockfilled: AntDesignIcons.LockFilled,
  lockoutlined: AntDesignIcons.LockOutlined,
  locktwotone: AntDesignIcons.LockTwoTone,
  loginoutlined: AntDesignIcons.LoginOutlined,
  logoutoutlined: AntDesignIcons.LogoutOutlined,
  maccommandfilled: AntDesignIcons.MacCommandFilled,
  maccommandoutlined: AntDesignIcons.MacCommandOutlined,
  mailfilled: AntDesignIcons.MailFilled,
  mailoutlined: AntDesignIcons.MailOutlined,
  mailtwotone: AntDesignIcons.MailTwoTone,
  manoutlined: AntDesignIcons.ManOutlined,
  medicineboxfilled: AntDesignIcons.MedicineBoxFilled,
  medicineboxoutlined: AntDesignIcons.MedicineBoxOutlined,
  medicineboxtwotone: AntDesignIcons.MedicineBoxTwoTone,
  mediumcirclefilled: AntDesignIcons.MediumCircleFilled,
  mediumoutlined: AntDesignIcons.MediumOutlined,
  mediumsquarefilled: AntDesignIcons.MediumSquareFilled,
  mediumworkmarkoutlined: AntDesignIcons.MediumWorkmarkOutlined,
  mehfilled: AntDesignIcons.MehFilled,
  mehoutlined: AntDesignIcons.MehOutlined,
  mehtwotone: AntDesignIcons.MehTwoTone,
  menufoldoutlined: AntDesignIcons.MenuFoldOutlined,
  menuoutlined: AntDesignIcons.MenuOutlined,
  menuunfoldoutlined: AntDesignIcons.MenuUnfoldOutlined,
  mergecellsoutlined: AntDesignIcons.MergeCellsOutlined,
  messagefilled: AntDesignIcons.MessageFilled,
  messageoutlined: AntDesignIcons.MessageOutlined,
  messagetwotone: AntDesignIcons.MessageTwoTone,
  minuscirclefilled: AntDesignIcons.MinusCircleFilled,
  minuscircleoutlined: AntDesignIcons.MinusCircleOutlined,
  minuscircletwotone: AntDesignIcons.MinusCircleTwoTone,
  minusoutlined: AntDesignIcons.MinusOutlined,
  minussquarefilled: AntDesignIcons.MinusSquareFilled,
  minussquareoutlined: AntDesignIcons.MinusSquareOutlined,
  minussquaretwotone: AntDesignIcons.MinusSquareTwoTone,
  mobilefilled: AntDesignIcons.MobileFilled,
  mobileoutlined: AntDesignIcons.MobileOutlined,
  mobiletwotone: AntDesignIcons.MobileTwoTone,
  moneycollectfilled: AntDesignIcons.MoneyCollectFilled,
  moneycollectoutlined: AntDesignIcons.MoneyCollectOutlined,
  moneycollecttwotone: AntDesignIcons.MoneyCollectTwoTone,
  monitoroutlined: AntDesignIcons.MonitorOutlined,
  moreoutlined: AntDesignIcons.MoreOutlined,
  nodecollapseoutlined: AntDesignIcons.NodeCollapseOutlined,
  nodeexpandoutlined: AntDesignIcons.NodeExpandOutlined,
  nodeindexoutlined: AntDesignIcons.NodeIndexOutlined,
  notificationfilled: AntDesignIcons.NotificationFilled,
  notificationoutlined: AntDesignIcons.NotificationOutlined,
  notificationtwotone: AntDesignIcons.NotificationTwoTone,
  numberoutlined: AntDesignIcons.NumberOutlined,
  onetooneoutlined: AntDesignIcons.OneToOneOutlined,
  orderedlistoutlined: AntDesignIcons.OrderedListOutlined,
  paperclipoutlined: AntDesignIcons.PaperClipOutlined,
  partitionoutlined: AntDesignIcons.PartitionOutlined,
  pausecirclefilled: AntDesignIcons.PauseCircleFilled,
  pausecircleoutlined: AntDesignIcons.PauseCircleOutlined,
  pausecircletwotone: AntDesignIcons.PauseCircleTwoTone,
  pauseoutlined: AntDesignIcons.PauseOutlined,
  paycirclefilled: AntDesignIcons.PayCircleFilled,
  paycircleoutlined: AntDesignIcons.PayCircleOutlined,
  percentageoutlined: AntDesignIcons.PercentageOutlined,
  phonefilled: AntDesignIcons.PhoneFilled,
  phoneoutlined: AntDesignIcons.PhoneOutlined,
  phonetwotone: AntDesignIcons.PhoneTwoTone,
  piccenteroutlined: AntDesignIcons.PicCenterOutlined,
  picleftoutlined: AntDesignIcons.PicLeftOutlined,
  picrightoutlined: AntDesignIcons.PicRightOutlined,
  picturefilled: AntDesignIcons.PictureFilled,
  pictureoutlined: AntDesignIcons.PictureOutlined,
  picturetwotone: AntDesignIcons.PictureTwoTone,
  piechartfilled: AntDesignIcons.PieChartFilled,
  piechartoutlined: AntDesignIcons.PieChartOutlined,
  piecharttwotone: AntDesignIcons.PieChartTwoTone,
  playcirclefilled: AntDesignIcons.PlayCircleFilled,
  playcircleoutlined: AntDesignIcons.PlayCircleOutlined,
  playcircletwotone: AntDesignIcons.PlayCircleTwoTone,
  playsquarefilled: AntDesignIcons.PlaySquareFilled,
  playsquareoutlined: AntDesignIcons.PlaySquareOutlined,
  playsquaretwotone: AntDesignIcons.PlaySquareTwoTone,
  pluscirclefilled: AntDesignIcons.PlusCircleFilled,
  pluscircleoutlined: AntDesignIcons.PlusCircleOutlined,
  pluscircletwotone: AntDesignIcons.PlusCircleTwoTone,
  plusoutlined: AntDesignIcons.PlusOutlined,
  plussquarefilled: AntDesignIcons.PlusSquareFilled,
  plussquareoutlined: AntDesignIcons.PlusSquareOutlined,
  plussquaretwotone: AntDesignIcons.PlusSquareTwoTone,
  poundcirclefilled: AntDesignIcons.PoundCircleFilled,
  poundcircleoutlined: AntDesignIcons.PoundCircleOutlined,
  poundcircletwotone: AntDesignIcons.PoundCircleTwoTone,
  poundoutlined: AntDesignIcons.PoundOutlined,
  poweroffoutlined: AntDesignIcons.PoweroffOutlined,
  printerfilled: AntDesignIcons.PrinterFilled,
  printeroutlined: AntDesignIcons.PrinterOutlined,
  printertwotone: AntDesignIcons.PrinterTwoTone,
  profilefilled: AntDesignIcons.ProfileFilled,
  profileoutlined: AntDesignIcons.ProfileOutlined,
  profiletwotone: AntDesignIcons.ProfileTwoTone,
  projectfilled: AntDesignIcons.ProjectFilled,
  projectoutlined: AntDesignIcons.ProjectOutlined,
  projecttwotone: AntDesignIcons.ProjectTwoTone,
  propertysafetyfilled: AntDesignIcons.PropertySafetyFilled,
  propertysafetyoutlined: AntDesignIcons.PropertySafetyOutlined,
  propertysafetytwotone: AntDesignIcons.PropertySafetyTwoTone,
  pullrequestoutlined: AntDesignIcons.PullRequestOutlined,
  pushpinfilled: AntDesignIcons.PushpinFilled,
  pushpinoutlined: AntDesignIcons.PushpinOutlined,
  pushpintwotone: AntDesignIcons.PushpinTwoTone,
  qqcirclefilled: AntDesignIcons.QqCircleFilled,
  qqoutlined: AntDesignIcons.QqOutlined,
  qqsquarefilled: AntDesignIcons.QqSquareFilled,
  qrcodeoutlined: AntDesignIcons.QrcodeOutlined,
  questioncirclefilled: AntDesignIcons.QuestionCircleFilled,
  questioncircleoutlined: AntDesignIcons.QuestionCircleOutlined,
  questioncircletwotone: AntDesignIcons.QuestionCircleTwoTone,
  questionoutlined: AntDesignIcons.QuestionOutlined,
  radarchartoutlined: AntDesignIcons.RadarChartOutlined,
  radiusbottomleftoutlined: AntDesignIcons.RadiusBottomleftOutlined,
  radiusbottomrightoutlined: AntDesignIcons.RadiusBottomrightOutlined,
  radiussettingoutlined: AntDesignIcons.RadiusSettingOutlined,
  radiusupleftoutlined: AntDesignIcons.RadiusUpleftOutlined,
  radiusuprightoutlined: AntDesignIcons.RadiusUprightOutlined,
  readfilled: AntDesignIcons.ReadFilled,
  readoutlined: AntDesignIcons.ReadOutlined,
  reconciliationfilled: AntDesignIcons.ReconciliationFilled,
  reconciliationoutlined: AntDesignIcons.ReconciliationOutlined,
  reconciliationtwotone: AntDesignIcons.ReconciliationTwoTone,
  redenvelopefilled: AntDesignIcons.RedEnvelopeFilled,
  redenvelopeoutlined: AntDesignIcons.RedEnvelopeOutlined,
  redenvelopetwotone: AntDesignIcons.RedEnvelopeTwoTone,
  redditcirclefilled: AntDesignIcons.RedditCircleFilled,
  redditoutlined: AntDesignIcons.RedditOutlined,
  redditsquarefilled: AntDesignIcons.RedditSquareFilled,
  redooutlined: AntDesignIcons.RedoOutlined,
  reloadoutlined: AntDesignIcons.ReloadOutlined,
  restfilled: AntDesignIcons.RestFilled,
  restoutlined: AntDesignIcons.RestOutlined,
  resttwotone: AntDesignIcons.RestTwoTone,
  retweetoutlined: AntDesignIcons.RetweetOutlined,
  rightcirclefilled: AntDesignIcons.RightCircleFilled,
  rightcircleoutlined: AntDesignIcons.RightCircleOutlined,
  rightcircletwotone: AntDesignIcons.RightCircleTwoTone,
  rightoutlined: AntDesignIcons.RightOutlined,
  rightsquarefilled: AntDesignIcons.RightSquareFilled,
  rightsquareoutlined: AntDesignIcons.RightSquareOutlined,
  rightsquaretwotone: AntDesignIcons.RightSquareTwoTone,
  riseoutlined: AntDesignIcons.RiseOutlined,
  robotfilled: AntDesignIcons.RobotFilled,
  robotoutlined: AntDesignIcons.RobotOutlined,
  rocketfilled: AntDesignIcons.RocketFilled,
  rocketoutlined: AntDesignIcons.RocketOutlined,
  rockettwotone: AntDesignIcons.RocketTwoTone,
  rollbackoutlined: AntDesignIcons.RollbackOutlined,
  rotateleftoutlined: AntDesignIcons.RotateLeftOutlined,
  rotaterightoutlined: AntDesignIcons.RotateRightOutlined,
  safetycertificatefilled: AntDesignIcons.SafetyCertificateFilled,
  safetycertificateoutlined: AntDesignIcons.SafetyCertificateOutlined,
  safetycertificatetwotone: AntDesignIcons.SafetyCertificateTwoTone,
  safetyoutlined: AntDesignIcons.SafetyOutlined,
  savefilled: AntDesignIcons.SaveFilled,
  saveoutlined: AntDesignIcons.SaveOutlined,
  savetwotone: AntDesignIcons.SaveTwoTone,
  scanoutlined: AntDesignIcons.ScanOutlined,
  schedulefilled: AntDesignIcons.ScheduleFilled,
  scheduleoutlined: AntDesignIcons.ScheduleOutlined,
  scheduletwotone: AntDesignIcons.ScheduleTwoTone,
  scissoroutlined: AntDesignIcons.ScissorOutlined,
  searchoutlined: AntDesignIcons.SearchOutlined,
  securityscanfilled: AntDesignIcons.SecurityScanFilled,
  securityscanoutlined: AntDesignIcons.SecurityScanOutlined,
  securityscantwotone: AntDesignIcons.SecurityScanTwoTone,
  selectoutlined: AntDesignIcons.SelectOutlined,
  sendoutlined: AntDesignIcons.SendOutlined,
  settingfilled: AntDesignIcons.SettingFilled,
  settingoutlined: AntDesignIcons.SettingOutlined,
  settingtwotone: AntDesignIcons.SettingTwoTone,
  shakeoutlined: AntDesignIcons.ShakeOutlined,
  sharealtoutlined: AntDesignIcons.ShareAltOutlined,
  shopfilled: AntDesignIcons.ShopFilled,
  shopoutlined: AntDesignIcons.ShopOutlined,
  shoptwotone: AntDesignIcons.ShopTwoTone,
  shoppingcartoutlined: AntDesignIcons.ShoppingCartOutlined,
  shoppingfilled: AntDesignIcons.ShoppingFilled,
  shoppingoutlined: AntDesignIcons.ShoppingOutlined,
  shoppingtwotone: AntDesignIcons.ShoppingTwoTone,
  shrinkoutlined: AntDesignIcons.ShrinkOutlined,
  signalfilled: AntDesignIcons.SignalFilled,
  sisternodeoutlined: AntDesignIcons.SisternodeOutlined,
  sketchcirclefilled: AntDesignIcons.SketchCircleFilled,
  sketchoutlined: AntDesignIcons.SketchOutlined,
  sketchsquarefilled: AntDesignIcons.SketchSquareFilled,
  skinfilled: AntDesignIcons.SkinFilled,
  skinoutlined: AntDesignIcons.SkinOutlined,
  skintwotone: AntDesignIcons.SkinTwoTone,
  skypefilled: AntDesignIcons.SkypeFilled,
  skypeoutlined: AntDesignIcons.SkypeOutlined,
  slackcirclefilled: AntDesignIcons.SlackCircleFilled,
  slackoutlined: AntDesignIcons.SlackOutlined,
  slacksquarefilled: AntDesignIcons.SlackSquareFilled,
  slacksquareoutlined: AntDesignIcons.SlackSquareOutlined,
  slidersfilled: AntDesignIcons.SlidersFilled,
  slidersoutlined: AntDesignIcons.SlidersOutlined,
  sliderstwotone: AntDesignIcons.SlidersTwoTone,
  smalldashoutlined: AntDesignIcons.SmallDashOutlined,
  smilefilled: AntDesignIcons.SmileFilled,
  smileoutlined: AntDesignIcons.SmileOutlined,
  smiletwotone: AntDesignIcons.SmileTwoTone,
  snippetsfilled: AntDesignIcons.SnippetsFilled,
  snippetsoutlined: AntDesignIcons.SnippetsOutlined,
  snippetstwotone: AntDesignIcons.SnippetsTwoTone,
  solutionoutlined: AntDesignIcons.SolutionOutlined,
  sortascendingoutlined: AntDesignIcons.SortAscendingOutlined,
  sortdescendingoutlined: AntDesignIcons.SortDescendingOutlined,
  soundfilled: AntDesignIcons.SoundFilled,
  soundoutlined: AntDesignIcons.SoundOutlined,
  soundtwotone: AntDesignIcons.SoundTwoTone,
  splitcellsoutlined: AntDesignIcons.SplitCellsOutlined,
  starfilled: AntDesignIcons.StarFilled,
  staroutlined: AntDesignIcons.StarOutlined,
  startwotone: AntDesignIcons.StarTwoTone,
  stepbackwardfilled: AntDesignIcons.StepBackwardFilled,
  stepbackwardoutlined: AntDesignIcons.StepBackwardOutlined,
  stepforwardfilled: AntDesignIcons.StepForwardFilled,
  stepforwardoutlined: AntDesignIcons.StepForwardOutlined,
  stockoutlined: AntDesignIcons.StockOutlined,
  stopfilled: AntDesignIcons.StopFilled,
  stopoutlined: AntDesignIcons.StopOutlined,
  stoptwotone: AntDesignIcons.StopTwoTone,
  strikethroughoutlined: AntDesignIcons.StrikethroughOutlined,
  subnodeoutlined: AntDesignIcons.SubnodeOutlined,
  swapleftoutlined: AntDesignIcons.SwapLeftOutlined,
  swapoutlined: AntDesignIcons.SwapOutlined,
  swaprightoutlined: AntDesignIcons.SwapRightOutlined,
  switcherfilled: AntDesignIcons.SwitcherFilled,
  switcheroutlined: AntDesignIcons.SwitcherOutlined,
  switchertwotone: AntDesignIcons.SwitcherTwoTone,
  syncoutlined: AntDesignIcons.SyncOutlined,
  tableoutlined: AntDesignIcons.TableOutlined,
  tabletfilled: AntDesignIcons.TabletFilled,
  tabletoutlined: AntDesignIcons.TabletOutlined,
  tablettwotone: AntDesignIcons.TabletTwoTone,
  tagfilled: AntDesignIcons.TagFilled,
  tagoutlined: AntDesignIcons.TagOutlined,
  tagtwotone: AntDesignIcons.TagTwoTone,
  tagsfilled: AntDesignIcons.TagsFilled,
  tagsoutlined: AntDesignIcons.TagsOutlined,
  tagstwotone: AntDesignIcons.TagsTwoTone,
  taobaocirclefilled: AntDesignIcons.TaobaoCircleFilled,
  taobaocircleoutlined: AntDesignIcons.TaobaoCircleOutlined,
  taobaooutlined: AntDesignIcons.TaobaoOutlined,
  taobaosquarefilled: AntDesignIcons.TaobaoSquareFilled,
  teamoutlined: AntDesignIcons.TeamOutlined,
  thunderboltfilled: AntDesignIcons.ThunderboltFilled,
  thunderboltoutlined: AntDesignIcons.ThunderboltOutlined,
  thunderbolttwotone: AntDesignIcons.ThunderboltTwoTone,
  totopoutlined: AntDesignIcons.ToTopOutlined,
  toolfilled: AntDesignIcons.ToolFilled,
  tooloutlined: AntDesignIcons.ToolOutlined,
  tooltwotone: AntDesignIcons.ToolTwoTone,
  trademarkcirclefilled: AntDesignIcons.TrademarkCircleFilled,
  trademarkcircleoutlined: AntDesignIcons.TrademarkCircleOutlined,
  trademarkcircletwotone: AntDesignIcons.TrademarkCircleTwoTone,
  trademarkoutlined: AntDesignIcons.TrademarkOutlined,
  transactionoutlined: AntDesignIcons.TransactionOutlined,
  translationoutlined: AntDesignIcons.TranslationOutlined,
  trophyfilled: AntDesignIcons.TrophyFilled,
  trophyoutlined: AntDesignIcons.TrophyOutlined,
  trophytwotone: AntDesignIcons.TrophyTwoTone,
  twittercirclefilled: AntDesignIcons.TwitterCircleFilled,
  twitteroutlined: AntDesignIcons.TwitterOutlined,
  twittersquarefilled: AntDesignIcons.TwitterSquareFilled,
  underlineoutlined: AntDesignIcons.UnderlineOutlined,
  undooutlined: AntDesignIcons.UndoOutlined,
  ungroupoutlined: AntDesignIcons.UngroupOutlined,
  unlockfilled: AntDesignIcons.UnlockFilled,
  unlockoutlined: AntDesignIcons.UnlockOutlined,
  unlocktwotone: AntDesignIcons.UnlockTwoTone,
  unorderedlistoutlined: AntDesignIcons.UnorderedListOutlined,
  upcirclefilled: AntDesignIcons.UpCircleFilled,
  upcircleoutlined: AntDesignIcons.UpCircleOutlined,
  upcircletwotone: AntDesignIcons.UpCircleTwoTone,
  upoutlined: AntDesignIcons.UpOutlined,
  upsquarefilled: AntDesignIcons.UpSquareFilled,
  upsquareoutlined: AntDesignIcons.UpSquareOutlined,
  upsquaretwotone: AntDesignIcons.UpSquareTwoTone,
  uploadoutlined: AntDesignIcons.UploadOutlined,
  usbfilled: AntDesignIcons.UsbFilled,
  usboutlined: AntDesignIcons.UsbOutlined,
  usbtwotone: AntDesignIcons.UsbTwoTone,
  useraddoutlined: AntDesignIcons.UserAddOutlined,
  userdeleteoutlined: AntDesignIcons.UserDeleteOutlined,
  useroutlined: AntDesignIcons.UserOutlined,
  userswitchoutlined: AntDesignIcons.UserSwitchOutlined,
  usergroupaddoutlined: AntDesignIcons.UsergroupAddOutlined,
  usergroupdeleteoutlined: AntDesignIcons.UsergroupDeleteOutlined,
  verifiedoutlined: AntDesignIcons.VerifiedOutlined,
  verticalalignbottomoutlined: AntDesignIcons.VerticalAlignBottomOutlined,
  verticalalignmiddleoutlined: AntDesignIcons.VerticalAlignMiddleOutlined,
  verticalaligntopoutlined: AntDesignIcons.VerticalAlignTopOutlined,
  verticalleftoutlined: AntDesignIcons.VerticalLeftOutlined,
  verticalrightoutlined: AntDesignIcons.VerticalRightOutlined,
  videocameraaddoutlined: AntDesignIcons.VideoCameraAddOutlined,
  videocamerafilled: AntDesignIcons.VideoCameraFilled,
  videocameraoutlined: AntDesignIcons.VideoCameraOutlined,
  videocameratwotone: AntDesignIcons.VideoCameraTwoTone,
  walletfilled: AntDesignIcons.WalletFilled,
  walletoutlined: AntDesignIcons.WalletOutlined,
  wallettwotone: AntDesignIcons.WalletTwoTone,
  warningfilled: AntDesignIcons.WarningFilled,
  warningoutlined: AntDesignIcons.WarningOutlined,
  warningtwotone: AntDesignIcons.WarningTwoTone,
  wechatfilled: AntDesignIcons.WechatFilled,
  wechatoutlined: AntDesignIcons.WechatOutlined,
  weibocirclefilled: AntDesignIcons.WeiboCircleFilled,
  weibocircleoutlined: AntDesignIcons.WeiboCircleOutlined,
  weibooutlined: AntDesignIcons.WeiboOutlined,
  weibosquarefilled: AntDesignIcons.WeiboSquareFilled,
  weibosquareoutlined: AntDesignIcons.WeiboSquareOutlined,
  whatsappoutlined: AntDesignIcons.WhatsAppOutlined,
  wifioutlined: AntDesignIcons.WifiOutlined,
  windowsfilled: AntDesignIcons.WindowsFilled,
  windowsoutlined: AntDesignIcons.WindowsOutlined,
  womanoutlined: AntDesignIcons.WomanOutlined,
  yahoofilled: AntDesignIcons.YahooFilled,
  yahoooutlined: AntDesignIcons.YahooOutlined,
  youtubefilled: AntDesignIcons.YoutubeFilled,
  youtubeoutlined: AntDesignIcons.YoutubeOutlined,
  yuquefilled: AntDesignIcons.YuqueFilled,
  yuqueoutlined: AntDesignIcons.YuqueOutlined,
  zhihucirclefilled: AntDesignIcons.ZhihuCircleFilled,
  zhihuoutlined: AntDesignIcons.ZhihuOutlined,
  zhihusquarefilled: AntDesignIcons.ZhihuSquareFilled,
  zoominoutlined: AntDesignIcons.ZoomInOutlined,
  zoomoutoutlined: AntDesignIcons.ZoomOutOutlined
}
