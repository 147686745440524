import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

const IbcSteepIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="56" height="56" viewBox="0 0 56 56"  {...props}>
      <G clipPath="url(#clip0_509_37812)">
        <Path
          d="M23.4924 12.6812V18.9723H11.2V49.452H28.1944L28.228 42.9255H33.333L33.2994 49.452L38.0015 49.4857L38.0351 38.2156H23.5595L23.526 44.7421H15.902V23.7158H28.1944V16.4491L41.9646 13.354V49.452H46.6666V7.46667L23.4924 12.6812Z"
          fill={fillColor}
        />
        <Path
          d="M20.1338 26.6427C20.1338 26.6427 20.033 26.6427 19.9994 26.71C19.9994 26.71 19.9658 26.7773 19.9658 26.8446V35.2214C19.9658 35.2214 19.9658 35.3224 19.9994 35.356C20.033 35.3897 20.0666 35.3897 20.1338 35.3897H22.1153C22.1153 35.3897 22.2161 35.3897 22.2497 35.356C22.2497 35.356 22.2832 35.2887 22.2832 35.2214V26.8446C22.2832 26.8446 22.2832 26.7436 22.2497 26.71C22.2161 26.6763 22.1825 26.6427 22.1153 26.6427H20.1338Z"
          fill={fillColor}
        />
        <Path
          d="M30.31 29.1322C30.31 28.2239 30.0413 27.5847 29.504 27.2146C28.9666 26.8446 28.1605 26.6427 27.0858 26.6427H23.828C23.828 26.6427 23.7272 26.6427 23.6936 26.71C23.6936 26.71 23.66 26.7773 23.66 26.8446V35.2214C23.66 35.2214 23.66 35.3224 23.6936 35.356C23.7272 35.3897 23.7608 35.3897 23.828 35.3897H27.1866C28.1605 35.3897 28.933 35.1878 29.5376 34.7841C30.1421 34.3804 30.4444 33.7412 30.4444 32.9001C30.4444 32.4291 30.3436 32.0591 30.1757 31.7227C30.0078 31.3862 29.7391 31.1171 29.336 30.9152C29.336 30.9152 29.336 30.8816 29.336 30.848C29.9742 30.4779 30.2764 29.906 30.2764 29.1322H30.31ZM26.011 28.7285C26.011 28.7285 26.011 28.6612 26.0782 28.6612H27.0858C27.6903 28.6612 27.9926 28.8967 27.9926 29.3341C27.9926 29.7714 27.6903 30.0406 27.0858 30.0406H26.0782C26.0782 30.0406 26.011 30.0406 26.011 29.9733V28.7285ZM27.2201 33.4048H26.0782C26.0782 33.4048 26.011 33.4048 26.011 33.3375V32.0254C26.011 32.0254 26.011 31.9582 26.0782 31.9582H27.2201C27.7911 31.9582 28.0934 32.1936 28.0934 32.6646C28.0934 33.1693 27.7911 33.4048 27.2201 33.4048Z"
          fill={fillColor}
        />
        <Path
          d="M34.1389 28.8631C34.3404 28.6612 34.6091 28.5603 34.9449 28.5603C35.2808 28.5603 35.5495 28.6612 35.751 28.8631C35.9525 29.0649 36.0533 29.334 36.0533 29.6705C36.0533 29.7714 36.0869 29.805 36.1876 29.805H36.2212L38.2028 29.6705C38.2028 29.6705 38.4043 29.6032 38.4043 29.5359C38.4043 28.9303 38.2699 28.3921 37.9677 27.9547C37.6654 27.5174 37.2624 27.1473 36.7586 26.9118C36.2548 26.6763 35.6502 26.5417 34.9449 26.5417C34.2396 26.5417 33.6687 26.6763 33.1313 26.9455C32.5939 27.2146 32.1909 27.5847 31.9222 28.0556C31.6199 28.5266 31.4856 29.0985 31.4856 29.7377V32.2609C31.4856 32.9001 31.6199 33.472 31.9222 33.943C32.1909 34.414 32.6275 34.8177 33.1313 35.0868C33.6687 35.356 34.2396 35.4905 34.9449 35.4905C35.6502 35.4905 36.2212 35.356 36.7586 35.1205C37.2959 34.8513 37.699 34.5149 37.9677 34.0439C38.2364 33.5729 38.4043 33.0683 38.4043 32.4628C38.4043 32.3955 38.3371 32.3282 38.2028 32.3282L36.2212 32.2273C36.2212 32.2273 36.1204 32.2273 36.0869 32.2273C36.0869 32.2273 36.0533 32.2945 36.0533 32.3282C36.0533 32.6646 35.9525 32.9337 35.751 33.1356C35.5495 33.3375 35.2808 33.4384 34.9449 33.4384C34.6091 33.4384 34.3404 33.3375 34.1389 33.1356C33.9374 32.9337 33.8366 32.6646 33.8366 32.3282V29.6368C33.8366 29.3004 33.9374 29.0313 34.1389 28.8294V28.8631Z"
          fill={fillColor}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_509_37812">
          <Rect width="56" height="56" fill={fillColor} />
        </ClipPath>
      </Defs>
    </Icon>
  )
}
export default IbcSteepIcon
