import React from 'react'
import { VStack, Heading, HStack } from 'native-base'
import { IStackProps } from 'native-base/lib/typescript/components/primitives/Stack'
import { IHeadingProps } from 'native-base/lib/typescript/components/primitives/Heading'
import CustomTooltip, { ITooltipProps } from '../__Helpers/Tooltip'

export interface IFormGroupProps extends IStackProps {
  title: string | JSX.Element
  headingProps?: IHeadingProps
  showTooltip?: boolean
  tooltipProps?: ITooltipProps
}

const nativeTriggerProps: ITooltipProps['triggerProps'] = {
  _android: { mt: '-2' },
  _ios: { mt: '-2' }
}

const FormGroup = ({
  title,
  children,
  headingProps = {},
  showTooltip = false,
  tooltipProps = {},
  ...props
}: IFormGroupProps) => (
  <VStack flex={1} fontSize="lg" {...props}>
    <Heading color="secondary.400" letterSpacing="0.04" {...headingProps}>
      {title}
      {showTooltip && (
        <CustomTooltip {...tooltipProps} triggerProps={nativeTriggerProps} />
      )}
    </Heading>
    {children}
  </VStack>
)

export default FormGroup
