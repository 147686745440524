import { Box, Input } from 'native-base'
import React, { forwardRef } from 'react'
import { IInputProps } from 'native-base/lib/typescript/components/primitives/Input'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'
import Base, { IIsolatedBaseProps, NativeBaseRef } from './Base.field'
import OptionToggle, { IOptionToggleProps } from '../../OptionToggle'

export interface IFormOptionToggleProps<T extends string | number>
  extends IOptionToggleProps<T>,
    IIsolatedBaseProps {
  labelProps?: IFormControlProps
}

function FormOptionToggleInner<T extends string | number>(
  {
    value,
    onChange,
    label,
    showAsOptional,
    showAsRequired,
    showTooltip,
    tooltipProps,
    error,
    labelProps = {},
    labelTextProps = {},
    ...rest
  }: IFormOptionToggleProps<T>,
  ref: React.ForwardedRef<any>,
) {
  return (
    <Base
      label={label}
      showAsOptional={showAsOptional}
      error={error}
      showAsRequired={showAsRequired}
      tooltipProps={tooltipProps}
      showTooltip={showTooltip}
      labelTextProps={labelTextProps}
      {...labelProps}>
      <OptionToggle
        ref={ref as NativeBaseRef}
        onChange={onChange}
        value={value}
        isInvalid={!!error}
        {...rest}
      />
    </Base>
  )
}

export const FormOptionToggle = React.forwardRef(FormOptionToggleInner) as <T extends string | number>(
  props: IFormOptionToggleProps<T> & { ref?: React.ForwardedRef<any> }
) => ReturnType<typeof FormOptionToggleInner>

export default FormOptionToggle
