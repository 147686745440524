import React, { forwardRef } from 'react'
import { StyleSheet } from 'react-native'
import Icon, { IIconPropsWithAntDesign } from '../Icon'
import { HStack, Pressable, Text, View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import { NativeBaseRef } from '../FormField/Fields/Base.field'

export type Option<T> = {
  value: T
  label?: string
  icon?: IIconPropsWithAntDesign['name'] | React.ReactElement
  disabled?: boolean
}

const baseStyles = StyleSheet.create({
  firstButton: {
    borderTopStartRadius: 4,
    borderBottomStartRadius: 4
  },
  lastButton: {
    borderTopEndRadius: 4,
    borderBottomEndRadius: 4
  }
})

export type ToggleButtonSize = 'md' | 'lg'

const styles: Record<ToggleButtonSize, any> = {
  md: StyleSheet.create({
    text: {
      fontSize: 14,
      lineHeight: 16.8
    },
    icon: {
      width: 20,
      height: 20
    }
  }),
  lg: StyleSheet.create({
    text: {
      fontSize: 20,
      lineHeight: 24
    },
    icon: {
      width: 21,
      height: 21
    }
  })
}

function OptionToggleButton<T>({
  value,
  label,
  icon,
  size = 'lg',
  isToggled = false,
  isDisabled = false,
  index = 0,
  count = 1,
  onPress
}: {
  isToggled?: boolean
  isDisabled?: boolean
  onPress?: (value: T) => void
  size?: ToggleButtonSize
  index?: number
  count?: number
} & Omit<Option<T>, 'disabled'>) {
  if (!['md', 'lg'].includes(size)) {
    console.warn(`OptionToggle: Unsupported size '${size}'. Defaulting to 'lg'`)
    size = 'lg'
  }
  const isFirst = index === 0
  const isLast = index === count - 1
  const isSingleOption = isFirst && isLast
  const hastLeftBorder = (isFirst && !isLast) || isSingleOption
  const hastRightBorder = (!isFirst && isLast) || isSingleOption

  return (
    <Pressable
      onPress={() => onPress && onPress(value)}
      flex="1"
      borderWidth="1"
      borderColor={isToggled ? 'cyan.500' : 'background.input'}
      borderLeftWidth={hastLeftBorder ? 1 : 0}
      borderRightWidth={hastRightBorder ? 1 : 0}
      style={[
        isFirst && baseStyles.firstButton,
        isLast && baseStyles.lastButton
      ]}
      bg={isToggled ? 'cyan.800' : 'background.boxes'}
      _hover={{
        bg: isToggled ? 'cyan.800' : 'background.hover'
      }}
      opacity={isDisabled ? 0.5 : 1}
      overflow="hidden"
      disabled={isDisabled}>
      <HStack
        alignItems="center"
        justifyContent="center"
        paddingY={size === 'md' ? '3.5' : '18px'}
        space={size === 'lg' ? '3' : '1'}>
        {icon && (
          <View>
            {typeof icon === 'string' ? (
              <Icon
                size="6"
                style={styles[size].icon}
                name={icon as IIconPropsWithAntDesign['name']}
                color="#fff"
                fillColor="#fff"
              />
            ) : (
              icon
            )}
          </View>
        )}
        <View>
          {label && (
            <Text fontWeight="500" style={styles[size].text}>
              {label}
            </Text>
          )}
        </View>
      </HStack>
      {isToggled && (
        <View
          position="absolute"
          bottom="0"
          w="full"
          h="3px"
          bg="cyan.500"></View>
      )}
    </Pressable>
  )
}

function OptionSeparator() {
  return <View w="1px" h="full" backgroundColor="background.input" />
}

export interface IOptionToggleProps<T extends string | number> {
  options: Option<T>[]
  value?: T | null
  onChange?: (value: T) => void
  size?: ToggleButtonSize
  isInvalid?: boolean
  isDisabled?: boolean
}

function OptionToggleInner<T extends string | number>(
  {
    value,
    onChange,
    options = [],
    size = 'lg',
    isInvalid = false,
    isDisabled = false,
    ...props
  }: IOptionToggleProps<T> & IViewProps,
  ref: React.ForwardedRef<any>,
) {
  return (
    <View ref={ref as NativeBaseRef} {...props}>
      <HStack borderWidth="1" borderColor={isInvalid ? 'error.400' : 'transparent'} borderRadius={4}>
        {options.map((opt, i) => (
          <HStack flex="1" key={`option-${opt.value}`}>
            <OptionToggleButton
              {...opt}
              isToggled={value === opt.value}
              isDisabled={isDisabled || opt.disabled}
              onPress={onChange}
              size={size}
              index={i}
              count={options.length}
            />
            {i !== options.length - 1 && <OptionSeparator />}
          </HStack>
        ))}
      </HStack>
    </View>
  )
}

export const OptionToggle = React.forwardRef(OptionToggleInner) as <T extends string | number>(
  props: IOptionToggleProps<T> & { ref?: React.ForwardedRef<any> }
) => ReturnType<typeof OptionToggleInner>

export default OptionToggle
