import React from 'react'
import {
  Text,
  VStack,
  HStack,
  Divider,
  View,
  IBoxProps,
  Box
} from 'native-base'
import Icon from '../../Icon'
import { IconName } from '../../Icon/types.icon'

const MONTH_FREQUENCY = 'mo'
const ANNUAL_FREQUENCY = 'yr'
const FREQUECY_VALUES = [MONTH_FREQUENCY, ANNUAL_FREQUENCY] as const
type PriceFrequencyType = typeof FREQUECY_VALUES[number]
interface IPlanBannerWithoutHeaderProps {
  icon?: IconName
  title?: string
  subTitle?: string
  price?: string
  marketingText?: string
  priceFrequency?: PriceFrequencyType
  isDisabled?: boolean
  titleProps?: Record<string, unknown>
}

export interface IPlanBannerProps extends IPlanBannerWithoutHeaderProps {
  header?: React.ReactNode | string
  divider?: boolean
  iconFull?: {
    size?: string
    color?: string
  }
}

const PlanBanner = ({
  title,
  subTitle,
  marketingText,
  isDisabled = false,
  titleProps
}: IPlanBannerWithoutHeaderProps) => (
  <VStack flex={1} opacity={isDisabled ? 0.4 : 1} w="100%">
    <HStack justifyContent="center" alignItems="center" flex={2} w="100%">
      <HStack alignItems="center" flex={1} mb="1" w="100%">
        <Text _web={{ verticalAlign: 'center' }} fontSize="xl" {...titleProps}>
          {title}
        </Text>
      </HStack>
    </HStack>
    <HStack justifyContent="flex-start" alignItems="flex-end" flex={1}>
      <Text fontSize="2xs" fontWeight="normal">
        {subTitle}
      </Text>
      {marketingText && (
        <Text
          flex={2}
          textAlign="right"
          letterSpacing={0.02}
          fontWeight="normal"
          fontSize="2xs">
          {marketingText}
        </Text>
      )}
    </HStack>
  </VStack>
)

interface IconFullProps {
  iconName: IconName
  iconSize?: string
  iconColor?: string
}
const IconFullElement = ({
  iconName,
  iconSize = '10',
  iconColor = 'primary.500'
}: IconFullProps) => (
  <View width={10} mr={2}>
    <Icon name={iconName} size={iconSize} color={iconColor} />
  </View>
)

const PlanBannerVariant = ({
  header,
  divider = true,
  iconFull,
  ...props
}: IPlanBannerProps) => {
  let restProps = { ...props }
  let iconName: IconName | undefined
  // in order to create a left side icon that delegate he data to othe place we create a variant with a boolean
  if (iconFull) {
    const { icon, ...rest } = props
    iconName = icon
    restProps = rest
  }

  const PlanWithHeader = (props: IPlanBannerProps): JSX.Element => (
    <Box
      display="flex"
      flexDirection="column"
      safeArea
      w="100%"
      bg="background.700"
      pr={5}
      borderRadius={16}>
      {header && (
        <View pt="5" pb="1" w="100%">
          {header}
        </View>
      )}
      {header && divider && <Divider />}
      {<PlanBanner {...props} />}
    </Box>
  )

  return (
    <Box w="100%" bg="background.700" borderRadius={16}>
      {iconName ? (
        <Box
          px="5"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          bg="background.700"
          borderRadius={16}>
          <IconFullElement
            iconName={iconName}
            iconColor={iconFull?.color}
            iconSize={iconFull?.size}
          />
          <PlanWithHeader {...restProps} />
        </Box>
      ) : (
        <PlanWithHeader {...props} />
      )}
    </Box>
  )
}

export default PlanBannerVariant
